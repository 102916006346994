import {useState, useEffect} from 'react';
import {Button, Input, Selection} from 'components';
import {useNavigate, useParams} from 'react-router-dom';
import * as service from 'services';
import {AlertValidation} from 'utils/validation';
import {topicOptions} from 'utils/topicOptions';

const NewTopic = () => {
  const navigate = useNavigate();
  const {topicId} = useParams();

  const [value, setValue] = useState({
    name: '',
    sources: []
  });
  const [caseName, setCaseName] = useState('');
  const [isFetch, setFetch] = useState(true);

  const fetchCaseManagement = async () => {
    setAlert(null);

    try {
      const data = await service.CaseManagement.getCaseById(topicId);
      setCaseName(data.name);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    fetchCaseManagement();
  }, []);

  const [alert, setAlert] = useState(null);
  const [isCreateLoading, setCreateLoading] = useState(false);

  const handleInputChange = (e) => {
    const id = (e.target && e.target.id) || e.id;
    const val = (e.target && e.target.value) || e.value || '';

    setValue((prev) => ({
      ...prev,
      [id]: val
    }));
  };

  const handleCreateUser = async (data) => {
    setAlert(null);
    setCreateLoading(true);

    const newData = {
      name: data.name,
      sources: data.sources.flatMap(({value}) => value)
    };

    const id = topicId === 'default' ? '' : topicId;

    try {
      await service.Topics.createNewTopic(newData, id);
      navigate(-1);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setCreateLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {alert}
      <div className="flex flex-row items-center gap-2">
        <div className="text-h-l">Buat Keyword Baru</div>
        {!isFetch && (
          <div className="gap-6 flex items-center">
            <div className="text-h-m">- [{caseName}]</div>
          </div>
        )}
      </div>
      <div className="w-full bg-ink-10 p-10 md:px-20 lg:px-32 lg:py-14 xl:px-40 xl:py-16 2xl:px-48 2xl:py-20 rounded-lg shadow-2 space-y-8">
        <Input
          id="name"
          label="Keyword"
          value={value.name}
          func={handleInputChange}
        />
        <Selection
          id="sources"
          label="Sumber"
          value={value.sources}
          options={topicOptions}
          func={handleInputChange}
          multiple
          searchable
        />
        <div className="flex flex-col sm:flex-row items-center justify-end">
          <div className="w-full sm:w-auto flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0">
            <Button
              otherClassName="order-last sm:order-first"
              variant="outlined"
              func={() => navigate(-1)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              func={() => handleCreateUser(value)}
              isLoading={isCreateLoading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTopic;
