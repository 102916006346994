import {useState, useEffect} from 'react';
import {
  Avatar,
  Badge,
  Table,
  Modal,
  Button,
  Dropdown,
  GraphLineChart,
  GraphSocialNetwork
} from 'components';
import {useParams} from 'react-router-dom';
import {
  dateFormatter,
  dateTimeFormatter,
  thousandFormatter,
  convert_number_str
} from 'utils/formatter';
import {Tooltip} from 'flowbite-react';
import {AlertValidation} from 'utils/validation';
import {optionsSocialMedia} from 'utils/topicOptions';
import * as service from 'services';
import ReactWordcloud from 'react-wordcloud';
import {Chart} from 'react-google-charts';
import {Spinner} from 'flowbite-react';
import {options} from 'utils/topicOptions';
import {
  NewspaperIcon,
  ArrowDownTrayIcon,
  InformationCircleIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  MinusIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';
import * as FileDownload from 'js-file-download';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import {connect} from 'react-redux';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const optionsWordCloud = {
  rotations: 1,
  rotationAngles: [0, 0],
  fontSizes: [15, 45],
  fontStyle: 'normal',
  fontWeight: 'normal'
};

const statusFormatter = (val) => {
  let variant;
  let value;
  if (val === 'positive') {
    variant = 'success';
    value = 'Positif';
  }
  if (val === 'negative') {
    variant = 'danger';
    value = 'Negatif';
  }
  if (val === 'neutral') {
    variant = 'info';
    value = 'Netral';
  }

  return (
    <div className="flex items-stretch justify-center flex-shrink-0">
      <Badge variant={variant}>{value}</Badge>
    </div>
  );
};

const photoFormatter = (cell, row) => {
  const {username, src} = row;
  return <Avatar name={username} img={cell} replace={src} />;
};

const popularFormatter = (cell) => {
  const comment = cell.replace(' ', '');
  return <div className="flex w-[250px]">{comment}</div>;
};

const thouFormatter = (cell, cellObject) => {
  let val = cell;

  if (typeof cell === 'object') {
    val = cellObject;
  }
  return <div>{thousandFormatter(val)}</div>;
};

const columns = [
  {
    dataField: 'id',
    text: 'No',
    headerAlign: 'center'
  },
  {
    dataField: 'avatar_thumbnail',
    text: 'Foto Profil',
    formatter: photoFormatter
  },
  {
    dataField: 'username',
    text: 'Username'
  },
  {
    dataField: 'followers',
    text: 'Jumlah Pengikut',
    formatter: (val) => thouFormatter(val, val.followers)
  },
  {
    dataField: 'comments_count',
    text: 'Jumlah Komentar',
    formatter: (val) => thouFormatter(val, val.comments_count)
  },
  {
    dataField: 'likes_count',
    text: 'Jumlah Likes',
    formatter: (val) => thouFormatter(val, val.likes_count)
  },
  {
    dataField: 'conversation_volume',
    text: 'Volume Pembicaraan dari Keyword yang Berhubungan',
    formatter: (val) => thouFormatter(val, val.conversation_volume)
  },
  {
    dataField: 'engagement_rate',
    text: 'Tingkat Keterlibatan',
    formatter: (val) => thouFormatter(val, val.engagement_rate)
  },
  {
    dataField: 'posting_frequency',
    text: 'Frekuensi Posting per Hari',
    formatter: (val) => thouFormatter(val, val.posting_frequency)
  },
  {
    dataField: 'most_popular_post',
    text: 'Unggahan Paling Populer',
    formatter: popularFormatter
  },
  {
    dataField: 'sentiment_analysis',
    text: 'Sentimen',
    formatter: statusFormatter
  }
];

const newsFormatter = (cell, row) => {
  return (
    <div className="space-y-3">
      <a
        className="line-clamp-2 text-primary-main hover:underline hover:underline-offset-[3px] focus:outline focus:outline-primary-focus focus:outline-[3px] focus:text-primary-hover active:outline-0 active:text-primary-pressed disabled:text-ink-60"
        href={row.news_url}
        target="_blank"
        rel="noreferrer"
      >
        {cell}
      </a>
      <div>
        <div>{dateTimeFormatter(row.news_date)}</div>
        <div>Oleh: {row.author}</div>
      </div>
    </div>
  );
};

const newsColumns = [
  {
    dataField: 'id',
    text: 'No',
    headerAlign: 'center'
  },
  {
    dataField: 'title',
    text: 'Berita',
    formatter: newsFormatter
  },
  {
    dataField: 'engagement_rate',
    text: 'Tingkat Ketertarikan',
    formatter: (val) => thouFormatter(val, val.engagement_rate)
  },
  {
    dataField: 'sentiment_analysis',
    text: 'Sentimen',
    formatter: statusFormatter
  }
];

const fixSelection = [
  {
    value: 'all',
    label: 'Semua'
  },
  ...optionsSocialMedia,
  {
    value: 'news',
    label: 'Berita'
  }
];

const AnalyticResultTopic = ({is_admin, user}) => {
  const {resultId: topicId} = useParams();
  const [alert, setAlert] = useState(null);
  const [isFetch, setFetch] = useState(true);
  const [isFetchWordCloud, setFetchWordCloud] = useState(true);
  const [isFetchSocialNetwork, setFetchSocialNetwork] = useState(true);
  const [isFetchDownload, setFetchDownload] = useState(false);
  const [isFetchRestart, setFetchRestart] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState({
    key: [],
    value: []
  });

  const [selection, setSelection] = useState({
    social_network_platform: [],
    social_network_sentiment: [
      {
        value: 'all',
        label: 'Semua'
      },
      {
        value: 'positive',
        label: 'Positif'
      },
      {
        value: 'negative',
        label: 'Negatif'
      },
      {
        value: 'neutral',
        label: 'Netral'
      }
    ]
  });
  const [newsTrendlinesData, setNewsTrendlinesData] = useState(null);
  const [mentionTrendlinesSentimentData, setMentionTrendlinesSentimentData] =
    useState(null);
  const [
    mentionTrendlinesSentimentChartData,
    setMentionTrendlinesSentimentChartData
  ] = useState(null);
  const [mentionTrendlinesSourcesData, setMentionTrendlinesSourcesData] =
    useState(null);
  const [
    mentionTrendlinesSourcesChartData,
    setMentionTrendlinesSourcesChartData
  ] = useState(null);
  const [sentimentTrendlinesData, setSentimentTrendlinesData] = useState(null);
  const [sentimentTrendlinesChartData, setSentimentTrendlinesChartData] =
    useState(null);

  const [filterValue, setFilterValue] = useState({
    social_network_platform: null,
    social_network_sentiment: selection.social_network_sentiment[0]
  });

  const [wordCloudValue, setWordCloudValue] = useState([]);
  const [socialNetworkValue, setSocialNetworkValue] = useState([]);

  const handleFilter = (source, sentiment) => {
    fetchSocialNetworkById(topicId, source, sentiment);
  };

  const [value, setValue] = useState(null);

  const dataChart = [
    ['Sentimen', 'Jumlah'],
    ['Positif', (value && value.sentiments.positive.total) || 0],
    ['Negatif', (value && value.sentiments.negative.total) || 0],
    ['Netral', (value && value.sentiments.neutral.total) || 0]
  ];

  const handleSentimentSource = (url) => {
    if (url.includes('twitter')) {
      return 'twitter';
    }
    if (url.includes('instagram')) {
      return 'instagram';
    }
    if (url.includes('facebook')) {
      return 'facebook';
    }
    if (url.includes('tiktok')) {
      return 'tiktok';
    }
    return '';
  };

  const fetchTopicDataById = async (id) => {
    try {
      const data = await service.Topics.getTopicById(id);
      if (data) {
        const sourcesPlatform = data.sources.map(
          (res) =>
            fixSelection.find((soc) => soc.value === res) ||
            fixSelection[fixSelection.length - 1]
        );
        let flattenSelectionPlatform = [...new Set(sourcesPlatform)];
        if (sourcesPlatform.length > 1) {
          flattenSelectionPlatform = [
            {
              value: 'all',
              label: 'Semua'
            },
            ...flattenSelectionPlatform
          ];
        }
        setSelection((prev) => ({
          ...prev,
          social_network_platform: flattenSelectionPlatform
        }));
        setFilterValue((prev) => ({
          ...prev,
          social_network_platform: flattenSelectionPlatform[0]
        }));
        const social_media_data = Object.keys(data.social_media_analysis)
          .filter((key) => data.sources.includes(key))
          .reduce((obj, key) => {
            const newData = data.social_media_analysis[key].map((res) => ({
              ...res,
              src: `./assets/img/logo-${key}.svg`
            }));
            obj[key] = newData;
            return obj;
          }, {});

        const social_media_analysis_key = Object.keys(social_media_data);
        let social_media_analysis_value = Object.values(social_media_data);
        social_media_analysis_value = social_media_analysis_value.map((res) =>
          res.map((response, i) => ({
            ...response,
            id: i + 1
          }))
        );

        setSocialMediaData({
          key: social_media_analysis_key,
          value: social_media_analysis_value
        });
        setNewsTrendlinesData({
          labels: data.articles_engagement.date || [],
          datasets: [
            {
              label: 'Artikel',
              data: data.articles_engagement.total_articles || [],
              borderColor: '#1A67C5',
              backgroundColor: '#1A67C5',
              yAxisID: 'y'
            },
            {
              type: 'bar',
              label: 'Tingkat Ketertarikan',
              data: data.articles_engagement.total_engagement || [],
              borderColor: '#EFC14B',
              backgroundColor: '#EFC14B',
              yAxisID: 'y1'
            }
          ]
        });
        setMentionTrendlinesSentimentData({
          labels: data.mentions_trendline.by_sentiments.date || [],
          datasets: [
            {
              label: 'Positif',
              data: data.mentions_trendline.by_sentiments.positive || [],
              borderColor: '#299326',
              backgroundColor: '#299326',
              yAxisID: 'y'
            },
            {
              label: 'Negatif',
              data: data.mentions_trendline.by_sentiments.negative || [],
              borderColor: '#B8100F',
              backgroundColor: '#B8100F',
              yAxisID: 'y'
            },
            {
              label: 'Netral',
              data: data.mentions_trendline.by_sentiments.neutral || [],
              borderColor: '#1A67C5',
              backgroundColor: '#1A67C5',
              yAxisID: 'y'
            }
          ]
        });
        setMentionTrendlinesSentimentChartData([
          ['Sentimen', 'Jumlah'],
          [
            'Positif',
            (data.mentions_trendline.by_sentiments.total_sentiments &&
              data.mentions_trendline.by_sentiments.total_sentiments.positive
                .total) ||
              0
          ],
          [
            'Negatif',
            (data.mentions_trendline.by_sentiments.total_sentiments &&
              data.mentions_trendline.by_sentiments.total_sentiments.negative
                .total) ||
              0
          ],
          [
            'Netral',
            (data.mentions_trendline.by_sentiments.total_sentiments &&
              data.mentions_trendline.by_sentiments.total_sentiments.neutral
                .total) ||
              0
          ]
        ]);
        setMentionTrendlinesSourcesData({
          labels: data.mentions_trendline.by_sources.date || [],
          datasets: [
            {
              label: 'Sosial Media',
              data: data.mentions_trendline.by_sources.social_media || [],
              borderColor: '#1A67C5',
              backgroundColor: '#1A67C5',
              yAxisID: 'y'
            },
            {
              label: 'Bukan Sosial Media',
              data: data.mentions_trendline.by_sources.non_social_media || [],
              borderColor: '#299326',
              backgroundColor: '#299326',
              yAxisID: 'y'
            }
          ]
        });
        setMentionTrendlinesSourcesChartData([
          ['Sentimen', 'Jumlah'],
          [
            'Mention dari Sosial Media',
            (data.mentions_trendline.by_sources.total_sources &&
              data.mentions_trendline.by_sources.total_sources.social_media
                .total) ||
              0
          ],
          [
            'Bukan Mention dari Sosial Media',
            (data.mentions_trendline.by_sources.total_sources &&
              data.mentions_trendline.by_sources.total_sources.non_social_media
                .total) ||
              0
          ]
        ]);
        setSentimentTrendlinesData({
          labels: data.sentiment_trendline.date || [],
          datasets: [
            {
              label: 'Positif',
              data: data.sentiment_trendline.positive || [],
              borderColor: '#299326',
              backgroundColor: '#299326',
              yAxisID: 'y'
            },
            {
              label: 'Negatif',
              data: data.sentiment_trendline.negative || [],
              borderColor: '#B8100F',
              backgroundColor: '#B8100F',
              yAxisID: 'y'
            },
            {
              label: 'Netral',
              data: data.sentiment_trendline.neutral || [],
              borderColor: '#1A67C5',
              backgroundColor: '#1A67C5',
              yAxisID: 'y'
            }
          ]
        });
        setSentimentTrendlinesChartData([
          ['Sentimen', 'Jumlah'],
          [
            'Positif',
            data.sentiment_trendline.total_sentiments.positive.total || 0
          ],
          [
            'Negatif',
            data.sentiment_trendline.total_sentiments.negative.total || 0
          ],
          [
            'Netral',
            data.sentiment_trendline.total_sentiments.neutral.total || 0
          ]
        ]);
        const newNewsTrending = data.news_trending.map((res, i) => ({
          id: i + 1,
          ...res
        }));
        const newNewsLatest = data.news_latest.map((res, i) => ({
          id: i + 1,
          ...res
        }));
        const newSentimentPositive = data.sentiment_positive.map((res) => ({
          ...res,
          src: `./assets/img/logo-${handleSentimentSource(res.url)}.svg`
        }));
        const newSentimentNegative = data.sentiment_negative.map((res) => ({
          ...res,
          src: `./assets/img/logo-${handleSentimentSource(res.url)}.svg`
        }));
        const newSentimentNeutral = data.sentiment_neutral.map((res) => ({
          ...res,
          src: `./assets/img/logo-${handleSentimentSource(res.url)}.svg`
        }));
        const newValue = {
          ...data,
          news_trending: newNewsTrending,
          news_latest: newNewsLatest,
          sentiment_positive: newSentimentPositive,
          sentiment_negative: newSentimentNegative,
          sentiment_neutral: newSentimentNeutral
        };
        setValue(newValue);
        setFetch(false);
      } else {
        throw new Error(
          'Data sedang diolah. Silahkan coba beberapa saat lagi.'
        );
      }
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  const fetchWordCloudById = async (id) => {
    try {
      const {word_cloud} = await service.Topics.getWordCloudById(id);
      const word = word_cloud.map(({word: text, frequency: value}) => ({
        text,
        value
      }));
      setWordCloudValue(word);
      setFetchWordCloud(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  const fetchSocialNetworkById = async (
    id,
    source = 'all',
    sentiment = 'all'
  ) => {
    const min = 10;
    const max = 250;
    const distance_factor = 2;
    setFetchSocialNetwork(true);
    try {
      const {graph_data} = await service.Topics.getSocialNetworkV2ById(
        id,
        source,
        sentiment,
        min,
        max,
        distance_factor
      );
      setSocialNetworkValue(graph_data || []);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
    setFetchSocialNetwork(false);
  };

  useEffect(() => {
    fetchTopicDataById(topicId);
    fetchSocialNetworkById(topicId);
    fetchWordCloudById(topicId);
  }, []);

  const Tabs = ({social_media}) => {
    const [tabActive, setTabActive] = useState(0);
    const [tabData, setTabData] = useState(social_media.value[0]);

    const handleTabClicked = (res) => {
      setTabActive(res);
      setTabData(social_media.value[res]);
    };

    return (
      <>
        {social_media && social_media.key.length > 1 && (
          <div className="mb-4 border-b border-gray-200">
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              {social_media.key.map((res, i) => {
                return (
                  <li className="mr-2" role="presentation" key={i}>
                    <button
                      className={`inline-block p-4  border-b-4 capitalize ${
                        tabActive !== i
                          ? 'border-transparent hover:text-gray-600 hover:border-primary-surface'
                          : 'border-primary-main text-primary-main'
                      }`}
                      id={`${res}-tab`}
                      type="button"
                      onClick={() => handleTabClicked(i)}
                    >
                      {res}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="pt-2 pb-3">
          <Table columns={columns} data={tabData} />
        </div>
      </>
    );
  };

  const handleSource = (value) => {
    const source = value.map((res) => {
      return options.find((topic) => res === topic.value).label;
    });

    const limitSource = source.slice(0, 3);

    return (
      <>
        {limitSource.map((res, i) => {
          return (
            <div key={res}>
              {res}
              {(i < limitSource.length - 1 || source.length > 3) && ','}
            </div>
          );
        })}
        {source.length > 3 && (
          <Modal
            variant="text"
            title="Sumber Keyword"
            btnTitle={'dan lain-lain.'}
            btmRightBtnTitle="Tutup"
            isBtmBtnLeft={false}
            btmRightBtnVariant="primary"
            size="no"
            xIcon
          >
            {source.join(', ')}
          </Modal>
        )}
      </>
    );
  };

  const statusFormatter = (val, badgeTypes) => {
    const {is_finished} = val;
    const variant = is_finished ? 'success' : 'secondary';
    const value = is_finished ? 'Selesai' : 'Proses';

    return (
      <div className="flex items-stretch justify-center flex-shrink-0">
        <Badge variant={variant} type={badgeTypes}>
          {value}
        </Badge>
      </div>
    );
  };

  const downloadAttachments = async (filename) => {
    setFetchDownload(true);
    try {
      const result = await service.Topics.downloadSocialNetworkById(topicId);
      FileDownload(result, `${filename}.csv`);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
    setFetchDownload(false);
  };

  const restartData = async () => {
    setFetchRestart(true);
    try {
      await service.Topics.restartTopicById(topicId);
      window.location.reload();
    } catch (err) {
      setAlert(AlertValidation(err));
    }
    setFetchRestart(false);
  };

  const WordCloudContainer = () =>
    isFetchWordCloud ? (
      <Spinner
        className="mr-3 fill-primary-main dark:text-ink-40"
        aria-label="Loading"
        size="lg"
      />
    ) : wordCloudValue.length > 0 ? (
      <ReactWordcloud options={optionsWordCloud} words={wordCloudValue} />
    ) : (
      'Tidak ada kata kunci'
    );

  const SocialNetworkContainer = () =>
    isFetchSocialNetwork ? (
      <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 text-center">
        <Spinner
          className="mr-3 fill-primary-main dark:text-ink-40"
          aria-label="Loading"
          size="lg"
        />
      </div>
    ) : socialNetworkValue.length > 0 ? (
      <GraphSocialNetwork source={value.topic_name} data={socialNetworkValue} />
    ) : (
      'Tidak ada Social Network Analysis'
    );

  const handleDownloadImage = async (id, name) => {
    const element = document.querySelector(`#${id}`);
    if (!element) {
      setAlert(AlertValidation(`Gambar tidak dapat diunduh!`));
      return;
    }

    const canvas = await html2canvas(element, {
      ignoreElements: (el) => {
        if ('downloadBtn' == el.id) {
          return true;
        }

        if (el.classList.contains('noDownload')) {
          return true;
        }
      }
    });
    const dataURL = canvas.toDataURL('image/jpeg');
    downloadjs(dataURL, `${name}.jpeg`, 'image/jpeg');
  };

  const CardCategory = ({title, size, src, value, icon}) => {
    return (
      <div className="w-full bg-ink-10 px-3 py-5 rounded-lg shadow-2 border border-ink-30">
        <div className="flex items-center justify-between font-bold gap-5">
          <div className="flex gap-2 items-center">
            {src && <img src={src} height={size} width={size} alt={title} />}
            {icon && icon}
            <span className="text-l truncate">{title}</span>
          </div>
          <div className="text-primary-main text-h-s truncate">{value}</div>
        </div>
      </div>
    );
  };

  const CardData = ({title, value, icon, iconValue}) => {
    return (
      <div className="w-full bg-ink-10 p-3 rounded-lg shadow-2 border border-ink-30">
        <div className="flex justify-between font-bold flex-col gap-2">
          <div
            className={`text-primary-main text-h-m flex gap-2 items-center ${
              iconValue && 'justify-between'
            }`}
          >
            {value}
            {iconValue && iconValue}
          </div>
          <div className="text-m flex gap-2">
            {title}
            <span className="text-s">{icon && icon}</span>
          </div>
        </div>
      </div>
    );
  };

  const SentimentCommentCard = ({headerColor, headerTitle, data = []}) => {
    const CommentCard = ({val}) => {
      const {
        avatar_thumbnail,
        date_time,
        engagement_rate,
        text,
        url,
        username,
        src
      } = val;
      return (
        <div className="flex w-full p-6 flex-col space-y-2">
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-2 items-center">
              <img
                src={avatar_thumbnail}
                height="30px"
                width="30px"
                alt={username}
                className="bg-ink-10 rounded-full"
                onError={(error) => (error.target.src = src)}
              />
              <span className="text-primary-main">{username}</span>
            </div>
            <div>{dateTimeFormatter(date_time)}</div>
          </div>
          <div className="line-clamp-3">{text}</div>
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-1 items-center">
              <img
                src={src}
                height="20px"
                width="20px"
                alt={username}
                className="rounded-full"
              />
              Tingkat Ketertarikan: {thousandFormatter(engagement_rate)}
            </div>
            <Button
              func={() => {
                window.open(url);
              }}
            >
              Detail
            </Button>
          </div>
        </div>
      );
    };

    return (
      <div className="w-full bg-ink-20 rounded-lg shadow-2">
        <div
          className={`text-center ${headerColor} text-white rounded-t-lg p-3`}
        >
          {headerTitle}
        </div>
        <div className="flex items-center text-s divide-y divide-ink-40 flex-col overflow-auto h-[300px]">
          {data.length > 0 ? (
            data.map((res, i) => {
              return <CommentCard val={res} key={i} />;
            })
          ) : (
            <div className="flex items-center justify-center text-m h-full">
              Tidak ada data
            </div>
          )}
        </div>
      </div>
    );
  };

  const HandleRatingIcon = ({val}) => {
    let icon = (
      <div className="text-ink-10 rounded-full bg-[#1A67C5] p-1.5 text-s">
        <Tooltip content="Netral" placement="bottom">
          <MinusIcon className="h-5 w-5" />
        </Tooltip>
      </div>
    );
    if (val <= 2) {
      icon = (
        <div className="text-ink-10 rounded-full bg-[#B8100F] p-1.5 text-s">
          <Tooltip content="Negatif" placement="bottom">
            <ArrowTrendingDownIcon className="h-5 w-5" />
          </Tooltip>
        </div>
      );
    }
    if (val >= 4) {
      icon = (
        <div className="text-ink-10 rounded-full bg-[#299326] p-1.5 text-s">
          <Tooltip content="Positif" placement="bottom">
            <ArrowTrendingUpIcon className="h-5 w-5" />
          </Tooltip>
        </div>
      );
    }
    return icon;
  };

  const container = isFetch ? (
    <div className="w-full bg-ink-10 p-10 md:px-20 lg:px-32 lg:py-14 xl:px-40 xl:py-16 2xl:px-48 2xl:py-20 rounded-lg shadow-2 text-center">
      <Spinner
        className="mr-3 fill-primary-main dark:text-ink-40"
        aria-label="Loading"
        size="lg"
      />
    </div>
  ) : (
    <div className="space-y-4">
      <div className="grid grid-cols-1 lg:grid-cols-5 space-y-4 gap-x-0 lg:space-y-0 lg:gap-x-4">
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6 col-span-1">
          <div className="flex flex-col space-y-4">
            <div className="space-y-1">
              <div className="font-bold">Dibuat Pada</div>
              <div>{dateFormatter(value.created_at)}</div>
            </div>
            <div className="space-y-1">
              <div className="font-bold">Dibuat Oleh</div>
              <div>{value.created_by.username}</div>
            </div>
            <div className="space-y-1">
              <div className="font-bold">Sumber</div>
              <div className="overflow-ellipsis overflow-hidden w-50">
                {handleSource(value.sources)}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6 col-span-4">
          <div className="text-h-s font-bold">Total Data</div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <CardData
              title="Total Akun"
              value={convert_number_str(value.account_total)}
            />
            <CardData
              title="Total Berita"
              value={convert_number_str(value.news_total)}
            />
            <CardData
              title="Total Pembicaraan"
              value={convert_number_str(value.conversation_volume)}
            />
            <CardData
              title="Total Ketertarikan"
              value={convert_number_str(value.engagement_total)}
            />
            <CardData
              title="Total Jangkauan"
              value={convert_number_str(value.reach_total)}
            />
            <CardData
              title="Rating Sentimen Berita"
              icon={
                <Tooltip
                  className="max-w-xs"
                  content="Tingkat sentimen pada berita. Sentimen cenderung negatif untuk rating yang mendekati 1 dan sentimen semakin positif jika mendekati 5.
                  "
                  placement="bottom"
                >
                  <InformationCircleIcon className="h-5 w-5 noDownload" />
                </Tooltip>
              }
              value={
                value.news_sentiment_rating === 0
                  ? 'N/A'
                  : thousandFormatter(value.news_sentiment_rating)
              }
              iconValue={
                value.news_sentiment_rating !== 0 && (
                  <HandleRatingIcon val={value.news_sentiment_rating} />
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
          <div className="flex items-top  justify-between h-full">
            <div className="flex flex-col flex-1 space-y-1">
              <div className="flex items-center gap-2 text-s">
                <div className="text-h-s font-bold">Akun Bot</div>
                <Tooltip
                  className="max-w-[250px] text-white"
                  content="Persentase akun media sosial yang diprediksi sebagai akun bot."
                  placement="bottom"
                >
                  <InformationCircleIcon className="h-5 w-5 noDownload" />
                </Tooltip>
              </div>
              <div className="text-[90px] text-center grow">
                {thousandFormatter(value.bots.percentage)} %
              </div>
              <div className="text-right">
                dari {thousandFormatter(value.account_total)} akun
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
          <div className="flex items-center gap-2 text-s">
            <div className="text-h-s font-bold">Kumpulan Kata Kunci</div>
            <Tooltip
              className="max-w-xs text-white"
              content="Kata-kata yang sering muncul pada posting media sosial dan berita mengenai keyword ini."
              placement="bottom"
            >
              <InformationCircleIcon className="h-5 w-5 noDownload" />
            </Tooltip>
          </div>
          <div className="w-full h-[180px] flex justify-center items-center">
            {isFetchWordCloud ? (
              <Spinner
                className="mr-3 fill-primary-main dark:text-ink-40"
                aria-label="Loading"
                size="lg"
              />
            ) : wordCloudValue.length > 0 ? (
              <ReactWordcloud
                options={optionsWordCloud}
                words={wordCloudValue}
              />
            ) : (
              'Tidak ada kata kunci'
            )}
          </div>
        </div>
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
          <div className="space-y-1">
            <div className="flex items-center gap-2 text-s">
              <div className="text-h-s font-bold">Sentimen</div>
              <Tooltip
                className="max-w-xs text-white"
                content="Sentimen dari posting media sosial dan berita."
                placement="bottom"
              >
                <InformationCircleIcon className="h-5 w-5 noDownload" />
              </Tooltip>
            </div>
            <Chart
              chartType="PieChart"
              data={dataChart}
              options={{
                chartArea: {left: 8, top: 8, right: 8, bottom: 8},
                pieSliceText: 'percentage',
                sliceVisibilityThreshold: 0,
                fontSize: 17,
                tooltip: {
                  trigger: 'none'
                },
                legend: {
                  position: 'labeled',
                  labeledValueText: 'value'
                },
                slices: {
                  0: {color: '#299326'},
                  1: {color: '#B8100F'},
                  2: {color: '#1A67C5'}
                }
              }}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
      </div>
      <div
        id="graph-social-network"
        className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6"
      >
        <div className="flex flex-col lg:flex-row justify-between gap-3">
          <div className="flex items-center gap-2 text-s">
            <div className="text-h-s font-bold">Analisis Keterhubungan</div>
            <Tooltip
              className="max-w-xs text-white"
              content="Menggambarkan tingkat keterkaitan akun media sosial atau portal berita terhadap keyword yang dianalisis.
              Warna biru menggambarkan akun dengan sentimen netral, warna hijau untuk akun dengan sentimen positif, dan warna merah untuk sentimen negatif.
              Semakin panjang garis menggambarkan tingkat keterkaitan yang semakin rendah dan semakin pendek menunjukkan tingkat keterkaitan tinggi."
              placement="bottom"
            >
              <InformationCircleIcon className="h-5 w-5 noDownload" />
            </Tooltip>
          </div>
          <Button
            id="downloadBtn"
            otherClassName="w-full lg:w-auto"
            func={() =>
              handleDownloadImage(
                'graph-social-network',
                `graph-social-network-${value.topic_name
                  .toLowerCase()
                  .replace(' ', '-')}`
              )
            }
            icon={<ArrowDownTrayIcon className="h-5 w-5" />}
          >
            Unduh
          </Button>
        </div>
        <div className="w-full items-center flex justify-center">
          {isFetchSocialNetwork ? (
            <div className="w-full bg-ink-10 p-40 rounded-lg space-y-3 text-center">
              <Spinner
                className="mr-3 fill-primary-main dark:text-ink-40"
                aria-label="Loading"
                size="lg"
              />
            </div>
          ) : socialNetworkValue.length > 0 ? (
            <GraphSocialNetwork
              source={value.topic_name}
              data={socialNetworkValue}
            />
          ) : (
            'Tidak ada Social Network Analysis'
          )}
        </div>
        <div className="w-full bg-ink-20 p-6 rounded-lg shadow-2 space-y-4 noDownload">
          <div className="text-h-s font-bold">Filter</div>
          <hr />
          <div className="text-m flex flex-col gap-3 lg:gap-6 lg:flex-row">
            <Dropdown
              id="social_network_platform"
              value={filterValue.social_network_platform}
              options={selection.social_network_platform}
              func={(e) =>
                setFilterValue((prev) => ({
                  ...prev,
                  social_network_platform: e.value
                }))
              }
              label="Platform"
              isSelection
            />
            <Dropdown
              id="social_network_sentiment"
              value={filterValue.social_network_sentiment}
              options={selection.social_network_sentiment}
              func={(e) =>
                setFilterValue((prev) => ({
                  ...prev,
                  social_network_sentiment: e.value
                }))
              }
              label="Sentimen"
              isSelection
            />
          </div>
          <hr />
          <div className="flex w-full justify-end">
            <Button
              func={() =>
                handleFilter(
                  filterValue.social_network_platform.value,
                  filterValue.social_network_sentiment.value
                )
              }
            >
              Filter
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
        <div className="flex items-center gap-2 text-s">
          <div className="text-h-s font-bold">Mention per Kategori</div>
          <Tooltip
            className="max-w-xs text-white"
            content="Jumlah pembicaraan per kategori."
            placement="bottom"
          >
            <InformationCircleIcon className="h-5 w-5 noDownload" />
          </Tooltip>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          <CardCategory
            title="Berita"
            icon={<NewspaperIcon className="h-5 w-5 mr-2" />}
            size="40px"
            value={convert_number_str(value.mentions_per_category.news)}
          />
          <CardCategory
            title="Instagram"
            src="./assets/img/logo-instagram.svg"
            size="40px"
            value={convert_number_str(value.mentions_per_category.instagram)}
          />
          <CardCategory
            title="Twitter (X)"
            src="./assets/img/logo-twitter.svg"
            size="20px"
            value={convert_number_str(value.mentions_per_category.twitter)}
          />
          <CardCategory
            title="TikTok"
            src="./assets/img/logo-tiktok.svg"
            size="40px"
            value={convert_number_str(value.mentions_per_category.tiktok)}
          />
          <CardCategory
            title="Facebook"
            src="./assets/img/logo-facebook.svg"
            size="30px"
            value={convert_number_str(value.mentions_per_category.facebook)}
          />
        </div>
      </div>
      {value.has_social_media && (
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
          <div className="flex items-center gap-2 text-s">
            <div className="text-h-s font-bold">10 Akun Teratas</div>
            <Tooltip
              className="max-w-xs text-white"
              content="Akun-akun media sosial dengan tingkat keterkaitan/keterlibatan tertinggi."
              placement="bottom"
            >
              <InformationCircleIcon className="h-5 w-5 noDownload" />
            </Tooltip>
          </div>
          <div className="w-full h-full">
            <Tabs social_media={socialMediaData} />
          </div>
        </div>
      )}
      <div
        id="trend-mention"
        className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6"
      >
        <div className="flex flex-col lg:flex-row justify-between gap-3">
          <div className="flex items-center gap-2 text-s">
            <div className="text-h-s font-bold">Tren Mention</div>
            <Tooltip
              className="max-w-xs text-white"
              content="Tren pembicaraan harian dari gabungan media sosial dan portal berita beserta sentimennya."
              placement="bottom"
            >
              <InformationCircleIcon className="h-5 w-5 noDownload" />
            </Tooltip>
          </div>
          <Button
            id="downloadBtn"
            otherClassName="w-full lg:w-auto"
            func={() =>
              handleDownloadImage(
                'trend-mention',
                `trend-mention-${value.topic_name
                  .toLowerCase()
                  .replace(' ', '-')}`
              )
            }
            icon={<ArrowDownTrayIcon className="h-5 w-5" />}
          >
            Unduh
          </Button>
        </div>
        <div className="grid grid-cols-5 lg:grid-cols-8">
          <div className="col-span-5">
            <GraphLineChart data={mentionTrendlinesSentimentData} noPoint />
          </div>
          <div className="col-span-3">
            <Chart
              chartType="PieChart"
              data={mentionTrendlinesSentimentChartData}
              options={{
                chartArea: {left: 50, top: 50, right: 50, bottom: 50},
                pieHole: 0.7,
                pieSliceText: 'none',
                sliceVisibilityThreshold: 0,
                fontSize: 12,
                tooltip: {
                  trigger: 'none'
                },
                legend: {
                  position: 'labeled',
                  labeledValueText: 'percentage'
                },
                slices: {
                  0: {color: '#299326'},
                  1: {color: '#B8100F'},
                  2: {color: '#1A67C5'}
                }
              }}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div className="grid grid-cols-5 lg:grid-cols-8">
          <div className="col-span-5">
            <GraphLineChart data={mentionTrendlinesSourcesData} />
          </div>
          <div className="col-span-3">
            <Chart
              chartType="PieChart"
              data={mentionTrendlinesSourcesChartData}
              options={{
                chartArea: {left: 50, top: 50, right: 50, bottom: 50},
                pieHole: 0.7,
                pieSliceText: 'none',
                sliceVisibilityThreshold: 0,
                fontSize: 12,
                tooltip: {
                  trigger: 'none'
                },
                legend: {
                  position: 'labeled',
                  labeledValueText: 'percentage'
                },
                slices: {
                  0: {color: '#1A67C5'},
                  1: {color: '#299326'}
                }
              }}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
      </div>
      {value.has_social_media && (
        <div
          id="trend-sentiment-social-media"
          className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6"
        >
          <div className="flex flex-col lg:flex-row justify-between gap-3">
            <div className="flex items-center gap-2 text-s">
              <div className="text-h-s font-bold">
                Tren Sentimen Media Sosial
              </div>
              <Tooltip
                className="max-w-xs text-white"
                content="Tren pembicaraan harian dari media sosial beserta sentimennya."
                placement="bottom"
              >
                <InformationCircleIcon className="h-5 w-5 noDownload" />
              </Tooltip>
            </div>
            <Button
              id="downloadBtn"
              otherClassName="w-full lg:w-auto"
              func={() =>
                handleDownloadImage(
                  'trend-sentiment-social-media',
                  `trend-sentiment-social-media-${value.topic_name
                    .toLowerCase()
                    .replace(' ', '-')}`
                )
              }
              icon={<ArrowDownTrayIcon className="h-5 w-5" />}
            >
              Unduh
            </Button>
          </div>
          <div className="grid grid-cols-5 lg:grid-cols-8">
            <div className="col-span-5">
              <GraphLineChart data={sentimentTrendlinesData} noPoint />
            </div>
            <div className="col-span-3">
              <Chart
                chartType="PieChart"
                data={sentimentTrendlinesChartData}
                options={{
                  chartArea: {left: 50, top: 50, right: 50, bottom: 50},
                  pieHole: 0.7,
                  pieSliceText: 'none',
                  sliceVisibilityThreshold: 0,
                  fontSize: 12,
                  tooltip: {
                    trigger: 'none'
                  },
                  legend: {
                    position: 'labeled',
                    labeledValueText: 'percentage'
                  },
                  slices: {
                    0: {color: '#299326'},
                    1: {color: '#B8100F'},
                    2: {color: '#1A67C5'}
                  }
                }}
                height={'100%'}
                width={'100%'}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 noDownload">
            <SentimentCommentCard
              headerColor="bg-[#299326]"
              headerTitle="Sentimen Positif"
              data={value.sentiment_positive}
            />
            <SentimentCommentCard
              headerColor="bg-primary-main"
              headerTitle="Sentimen Negatif"
              data={value.sentiment_negative}
            />
            <SentimentCommentCard
              headerColor="bg-[#1A67C5]"
              headerTitle="Sentimen Netral"
              data={value.sentiment_neutral}
            />
          </div>
        </div>
      )}
      {value.has_news && (
        <>
          <div
            id="trend-news"
            className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6"
          >
            <div className="flex flex-col lg:flex-row justify-between gap-3">
              <div className="flex items-center gap-2 text-s">
                <div className="text-h-s font-bold">Tren Berita</div>
                <Tooltip
                  className="max-w-xs text-white"
                  content="Tren harian portal berita yang menggambarkan jumlah artikel dan tingkat ketertarikan masyarakat terhadap berita tersebut."
                  placement="bottom"
                >
                  <InformationCircleIcon className="h-5 w-5 noDownload" />
                </Tooltip>
              </div>
              <Button
                id="downloadBtn"
                otherClassName="w-full lg:w-auto"
                func={() =>
                  handleDownloadImage(
                    'trend-news',
                    `trend-news-${value.topic_name
                      .toLowerCase()
                      .replace(' ', '-')}`
                  )
                }
                icon={<ArrowDownTrayIcon className="h-5 w-5" />}
              >
                Unduh
              </Button>
            </div>
            <div className="w-full h-full">
              <GraphLineChart
                data={newsTrendlinesData}
                y1Axis
                yAxisTitle="Artikel"
                y1AxisTitle="Tingkat Ketertarikan"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-4">
            <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6 col-span-1">
              <div className="flex items-center gap-2 text-s">
                <div className="text-h-s font-bold">Berita Terpopuler</div>
                <Tooltip
                  className="max-w-xs text-white"
                  content="10 berita dengan komentar terbanyak."
                  placement="bottom"
                >
                  <InformationCircleIcon className="h-5 w-5 noDownload" />
                </Tooltip>
              </div>
              <Table columns={newsColumns} data={value.news_trending} />
            </div>
            <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
              <div className="flex items-center gap-2 text-s">
                <div className="text-h-s font-bold">Berita Terbaru</div>
                <Tooltip
                  className="max-w-xs text-white"
                  content="10 berita terbaru."
                  placement="bottom"
                >
                  <InformationCircleIcon className="h-5 w-5 noDownload" />
                </Tooltip>
              </div>
              <Table columns={newsColumns} data={value.news_latest} />
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      {alert}
      <div className="flex flex-col lg:flex-row items-left lg:items-center justify-between gap-3">
        <div className="flex flex-row items-center gap-2">
          <div className="text-h-l">Hasil Analisis</div>
          {!isFetch && (
            <div className="gap-6 flex items-center">
              <div className="text-h-m">- [{value.topic_name}]</div>
              <div>{statusFormatter(value, 'title')}</div>
            </div>
          )}
        </div>
        {!isFetch && (
          <div className="w-full lg:w-auto space-x-3">
            {((is_admin && value.is_finished) || user === 'admin123') && (
              <Button func={() => restartData()}>
                <div className={`${isFetchRestart && 'animate-spin'}`}>
                  <ArrowPathIcon className="h-5 w-5" />
                </div>
              </Button>
            )}
            <Button
              func={() =>
                downloadAttachments(
                  `keyword-${value.topic_name.toLowerCase().replace(' ', '-')}`
                )
              }
              icon={<ArrowDownTrayIcon className="h-5 w-5" />}
              isLoading={isFetchDownload}
            >
              Unduh CSV
            </Button>
          </div>
        )}
      </div>
      {container}
    </div>
  );
};

const mapStateToProps = (state) => ({
  is_admin: state.auth.isAdmin,
  user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(AnalyticResultTopic);
