import {useState, useEffect} from 'react';
import {Spinner} from 'flowbite-react';
import * as service from 'services';
import {AlertValidation} from 'utils/validation';
import {Badge, GraphMapClustering} from 'components';
import {dateTimeFormatter} from 'utils/formatter';

const Dashboard = () => {
  const [alert, setAlert] = useState();
  const [isFetch, setFetch] = useState(true);
  const [data, setData] = useState({
    tiktok: [],
    instagram: [],
    facebook: [],
    twitter: [],
    news: [],
    trending_news_list: [],
    trending_news_maps: []
  });

  const fetchDashboardData = async () => {
    try {
      const data = await service.Dashboard.getAllDashboard();
      setData({
        tiktok: data.tiktok || [],
        instagram: data.instagram || [],
        facebook: data.facebook || [],
        twitter: data.twitter || [],
        news: data.news || [],
        trending_news_list: data.trending_news_list || [],
        trending_news_maps: data.trending_news_maps || []
      });
      setFetch(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const statusFormatter = (source) => {
    return (
      <div className="flex items-stretch justify-center flex-shrink-0">
        <Badge variant="secondary">{source}</Badge>
      </div>
    );
  };

  const TrendingSection = ({
    trends = [],
    suffix = 'komentar',
    numeric = '',
    prefix = '#'
  }) => {
    return trends.length > 0 ? (
      trends.map((res, i) => {
        return (
          <div className="truncate" key={i + 1}>
            {numeric === '' ? i + 1 : i + numeric}.
            <span className={`font-bold ${i < 9 ? 'ml-3' : 'ml-1'}`}>
              {prefix}
              {res.topic_name}
            </span>
            <div className="text-m ml-6 truncate">
              {res.volume_shorten} {suffix}
            </div>
          </div>
        );
      })
    ) : (
      <div className="text-center p-5">Tidak ada data</div>
    );
  };

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Beranda</div>
      {isFetch ? (
        <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 text-center">
          <Spinner
            className="mr-3 fill-primary-main dark:text-ink-40"
            aria-label="Loading"
            size="lg"
          />
        </div>
      ) : (
        <>
          <div className="w-full bg-ink-10 rounded-lg shadow-2 p-6 space-y-6">
            <div className="flex gap-2 text-h-s font-bold">Tren Berita</div>
            <GraphMapClustering mapData={data.trending_news_maps} />
            {data.trending_news_list.length > 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 shadow-2 rounded-lg border border-ink-20 px-5 py-2">
                <div className="w-full flex items-center flex-col divide-y divide-ink-40">
                  {data.trending_news_list
                    .slice(
                      0,
                      data.trending_news_list.length / 2 +
                        (data.trending_news_list.length % 2 === 1 ? 1 : 0)
                    )
                    .map((res, i) => (
                      <div
                        className="w-full flex flex-col space-y-2 py-4 px-3"
                        key={i}
                      >
                        <div className="flex items-center justify-between gap-10 text-m">
                          <div>{statusFormatter(res.news_name)}</div>
                          <div>{dateTimeFormatter(res.news_date)}</div>
                        </div>
                        <div className="flex items-center justify-between gap-10">
                          <a
                            className=" truncate text-primary-main text-l hover:underline hover:underline-offset-[3px] focus:outline focus:outline-primary-focus focus:outline-[3px] focus:text-primary-hover active:outline-0 active:text-primary-pressed disabled:text-ink-60',"
                            href={res.news_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {res.title}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="w-full flex items-center flex-col divide-y divide-ink-40">
                  {data.trending_news_list
                    .slice(
                      data.trending_news_list.length / 2 +
                        (data.trending_news_list.length % 2 === 1 ? 1 : 0),
                      data.trending_news_list.length
                    )
                    .map((res, i) => (
                      <div
                        className="w-full flex flex-col space-y-2 py-4 px-3"
                        key={i}
                      >
                        <div className="flex items-center justify-between gap-10 text-m">
                          <div>{statusFormatter(res.news_name)}</div>
                          <div>{dateTimeFormatter(res.news_date)}</div>
                        </div>
                        <div className="flex items-center justify-between gap-10">
                          <a
                            className=" truncate text-primary-main text-l hover:underline hover:underline-offset-[3px] focus:outline focus:outline-primary-focus focus:outline-[3px] focus:text-primary-hover active:outline-0 active:text-primary-pressed disabled:text-ink-60',"
                            href={res.news_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {res.title}
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="shadow-2 rounded-lg border border-ink-20 p-10 text-center">
                Tidak ada berita terpopuler
              </div>
            )}
          </div>
          <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-6">
            <div className="flex gap-2 text-h-s font-bold">
              Keyword Populer Mingguan
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 grid-cols-1">
                <div className="flex gap-2">
                  <img
                    src="./assets/img/logo-twitter.svg"
                    height="20px"
                    width="20px"
                    alt="x"
                  />
                  <span className="text-h-s font-bold">Twitter (X)</span>
                </div>
                <TrendingSection
                  trends={data.twitter}
                  prefix={null}
                  suffix="tweets"
                />
              </div>
              <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 grid-cols-1">
                <div className="flex gap-2">
                  <img
                    src="./assets/img/logo-instagram.svg"
                    height={'40px'}
                    width={'40px'}
                    alt="IG"
                  />
                  <span className="text-h-s font-bold">Instagram</span>
                </div>
                <TrendingSection trends={data.instagram} />
              </div>
              <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 grid-cols-1">
                <div className="flex gap-2">
                  <img
                    src="./assets/img/logo-tiktok.svg"
                    height="40px"
                    width="40px"
                    alt="Tiktok"
                  />
                  <span className="text-h-s font-bold">TikTok</span>
                </div>
                <TrendingSection trends={data.tiktok} suffix="impresi" />
              </div>
              <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 grid-cols-1">
                <div className="flex gap-2">
                  <img
                    src="./assets/img/logo-facebook.svg"
                    height="30px"
                    width="30px"
                    alt="FB"
                  />
                  <span className="text-h-s font-bold">Facebook</span>
                </div>
                <TrendingSection
                  trends={data.facebook}
                  prefix={null}
                  suffix="pembicaraan"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
