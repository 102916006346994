import {Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

const PublicRoute = ({Component, isLoggedIn}) => {
  const isAuthenticated = isLoggedIn || sessionStorage.getItem('token');
  const location = useLocation();

  return isAuthenticated ? (
    <Navigate
      replace
      to={{
        pathname: '/',
        state: {from: location}
      }}
    />
  ) : (
    <Component />
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PublicRoute);
