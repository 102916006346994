import ForceGraph2D from 'react-force-graph-2d';
import {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import * as d3 from 'd3';

const GraphSocialNetwork = ({data}) => {
  const fgRef = useRef();
  const NODE_R = 8;

  const graphData = useMemo(() => {
    let centerNode = data.find((res) => res.is_center);

    let nodesValue = data.flatMap((res, i) => {
      const nodeParent = [
        {
          id: res.topic_name,
          name: res.topic_name,
          parent: true,
          neighbors: []
        }
      ];
      const nodeChild =
        res.accounts.length > 0 &&
        res.accounts.map((acc) => ({
          id: acc.username,
          name: acc.username,
          sentiment: acc.sentiment_analysis,
          engagement_rate: acc.engagement_rate,
          distance: acc.distance,
          profile_url: acc.profile_url,
          parent: false,
          neighbors: []
        }));

      return [nodeParent, nodeChild].filter(Boolean).flat();
    });

    const uniqueKey = 'id';
    const uniqueNodesValue = [
      ...new Map(nodesValue.map((item) => [item[uniqueKey], item])).values()
    ];

    let linksValue = data.flatMap((res) => {
      const linkParent = [
        {
          key: res.topic_name,
          source: centerNode.topic_name,
          target: res.topic_name,
          distance: res.distance,
          sentiment: res.sentiment_analysis
        }
      ];

      const linkChild =
        res.accounts.length > 0 &&
        res.accounts.map((acc) => ({
          key: acc.username,
          source: res.topic_name,
          target: acc.username,
          distance: acc.distance,
          sentiment: acc.sentiment_analysis
        }));

      return [linkParent, linkChild].filter(Boolean).flat();
    });

    const gData = {nodes: uniqueNodesValue, links: linksValue};

    // cross-link node objects
    gData.links.forEach((link) => {
      const a = gData.nodes.find((res) => res.id === link.source);
      const b = gData.nodes.find((res) => res.id === link.target);

      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    return gData;
  }, [data]);

  const handleColor = (node) => {
    const {sentiment} = node;
    let color = '#EFC14B';

    if (sentiment === 'positive') {
      color = '#299326';
    }

    if (sentiment === 'negative') {
      color = '#B8100F';
    }

    if (sentiment === 'neutral') {
      color = '#1A67C5';
    }
    return color;
  };

  const [isWidth, setWidth] = useState(
    window.innerWidth > 640 ? window.innerWidth - 400 : window.innerWidth - 200
  );
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
      node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  const paintRing = useCallback(
    (node, ctx) => {
      // add ring just for highlighted nodes
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = node === hoverNode ? 'lime' : 'cyan';
      ctx.fill();
    },
    [hoverNode]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(
        window.innerWidth > 640
          ? window.innerWidth - 400
          : window.innerWidth - 200
      );
    });
  }, [window]);

  useEffect(() => {
    const fg = fgRef.current;

    // Deactivate existing forces
    // fg.d3Force('center', null);
    // fg.d3Force('charge', null);

    // // Add collision and bounding box forces
    const collide = d3.forceCollide(10);
    collide.strength = 1;
    collide.radius = 5;
    fg.d3Force('collide', collide);

    fg.d3Force('link').distance((node) => {
      let distance = node.distance > 250 ? 400 : node.distance;
      return distance;
    });
  }, [data]);

  return (
    <ForceGraph2D
      ref={fgRef}
      graphData={graphData}
      nodeRelSize={NODE_R}
      autoPauseRedraw={false}
      enableNodeDrag={false}
      linkWidth={(link) => (highlightLinks.has(link) ? 5 : 1)}
      linkDirectionalParticles={4}
      linkDirectionalParticleWidth={(link) =>
        highlightLinks.has(link) ? 4 : 0
      }
      width={isWidth}
      height={window.innerWidth > 640 ? isWidth / 1.5 : isWidth * 2}
      nodeCanvasObjectMode={(node) =>
        highlightNodes.has(node) ? 'before' : undefined
      }
      nodeCanvasObject={paintRing}
      onNodeHover={handleNodeHover}
      onLinkHover={handleLinkHover}
      nodeColor={(node) => handleColor(node)}
      onNodeClick={(node) => !node.parent && window.open(node.profile_url)}
    />
  );
};

export default GraphSocialNetwork;
