import {
  XMarkIcon,
  MagnifyingGlassIcon,
  EyeSlashIcon,
  EyeIcon
} from '@heroicons/react/20/solid';
import {useState} from 'react';

const cursor = {
  pointer: 'cursor-pointer',
  wait: 'cursor-wait',
  notAllowed: 'cursor-not-allowed'
};
const Input = ({
  id,
  placeholder,
  value,
  label,
  type = 'text',
  func = () => {},
  xFunc = () => {},
  rows = 2,
  xIcon = false,
  searchIcon = false,
  passIcon = false,
  disabled = false,
  required = false,
  readOnly = false
}) => {
  let cursorClasses = cursor[disabled ? 'notAllowed' : 'pointer'];
  let [typeStatus, setTypeStatus] = useState(passIcon ? 'password' : type);
  let prefix = searchIcon;
  let suffix = xIcon;
  let inputClasses = `
    bg-ink-10 border border-ink-50 text-m text-regular rounded-lg block w-full py-2 px-2.5 ${
      prefix && 'pl-10'
    } ${suffix && 'pr-10'}
    hover:border-primary-main
    focus:border-primary-main focus:outline focus:outline-[3px] focus:outline-primary-focus focus:outline-offset-0 focus:text-ink-100 
    disabled:bg-ink-30 disabled:border-ink-50
    read-only:bg-ink-30 read-only:border-ink-50 
    `;

  let inputType =
    typeStatus === 'textarea' ? (
      <textarea
        id={id}
        className={inputClasses}
        placeholder={placeholder}
        onChange={(e) => func(e)}
        value={value}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        rows={rows}
      >
        {value}
      </textarea>
    ) : (
      <input
        type={typeStatus}
        id={id}
        value={value}
        className={inputClasses}
        placeholder={placeholder}
        onChange={(e) => func(e)}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete="off"
      />
    );

  return (
    <div className="space-y-3 w-full">
      {label && (
        <label htmlFor={id} className="block text-m font-bold text-ink-100">
          {label}
        </label>
      )}
      <div
        className={`flex items-center relative text-ink-${
          disabled || readOnly ? '60' : `${value === '' ? '70' : '100'}`
        }`}
      >
        {searchIcon && (
          <MagnifyingGlassIcon
            className={`absolute left-2.5 h-5 w-5 ${cursorClasses}`}
            onClick={xFunc}
          />
        )}
        {inputType}
        {value && xIcon && (
          <XMarkIcon
            className={`absolute bottom-2 right-2.5 h-5 w-5 ${cursorClasses}`}
            onClick={xFunc}
          />
        )}
        {passIcon &&
          (typeStatus === 'password' ? (
            <EyeSlashIcon
              className={`absolute bottom-2 right-2.5 h-5 w-5 ${cursorClasses} text-ink-70`}
              onClick={() => setTypeStatus('text')}
            />
          ) : (
            <EyeIcon
              className={`absolute bottom-2 right-2.5 h-5 w-5 ${cursorClasses} text-ink-70`}
              onClick={() => setTypeStatus('password')}
            />
          ))}
      </div>
    </div>
  );
};

export default Input;
