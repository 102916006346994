import axios from 'utils/axios';

export const getAllAccount = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get('/settings/instagram', {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const createNewAccount = (res) => {
  const {username, password, email_address, email_password} = res;
  return new Promise((resolve, reject) =>
    axios
      .post('/settings/instagram', {
        username,
        password,
        email_address,
        email_password
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getAccountById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/settings/instagram/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const updateAccountById = (res) => {
  const {id, username, password, email_address, email_password} = res;
  return new Promise((resolve, reject) =>
    axios
      .put(`/settings/instagram/${id}`, {
        username,
        password,
        email_address,
        email_password
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const deleteAccountById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .delete(`/settings/instagram/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
