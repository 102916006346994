import {useEffect, useState} from 'react';
import {Button} from 'components';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid';

const Dropdown = ({
  id,
  label,
  options,
  name,
  value = null,
  isSelection = false,
  variant = 'dropdown',
  disabled,
  func = () => {},
  otherClassName
}) => {
  const width =
    'w-full flex items-center justify-between shadow-none min-w-[70px]';
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const title = isSelection
    ? (selected && selected.label) || <span className="text-ink-70">Pilih</span>
    : name;

  const handleSelectedMenu = (res) => {
    func({
      id: id,
      value: res
    });
    res.func && res.func();
    setSelected(res);
    setIsOpen(false);
  };

  const Modal = ({res}) => {
    const {disabled, label, icon, func} = res;
    const [showModal, setShowModal] = useState(false);
    const defaultClasses =
      'w-full text-left p-2 rounded-lg transition duration-200';
    const variantClasses =
      'text-danger-main hover:bg-danger-surface focus:bg-danger-surface focus:outline focus:outline-[3px] focus:outline-danger-border';
    return (
      <>
        <button
          type="button"
          className={`text-sm ${defaultClasses} ${variantClasses}`}
          disabled={disabled}
          onClick={() => setShowModal(true)}
        >
          <div className="flex w-full">
            {icon && <span className="mr-2">{icon}</span>}
            {label}
          </div>
        </button>
        {showModal && (
          <>
            <div className="transition flex justify-center items-center overflow-x-hidden overflow-y-auto px-5 sm:px-0 fixed inset-0 z-50 bg-ink-90 bg-opacity-50 ml-0">
              <div className="relative w-auto my-6 mx-auto 2xl:max-w-3xl lg:max-w-xl sm:max-w-md">
                <div className="shadow-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
                  <div className="p-6 text-left">
                    <div className={`relative ${icon ? 'pl-10' : ''}`}>
                      <dt className="mb-3 text-h-s font-bold text-ink-100">
                        {icon && (
                          <div
                            className={`${variantClasses} absolute left-0 top-0 flex items-center ml-0`}
                          >
                            {icon}
                          </div>
                        )}
                        Hapus Pengguna
                      </dt>
                      <dd className="text-m font-regular text-ink-80">
                        Apa kamu yakin ingin menghapus pengguna ini?
                      </dd>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-end bg-ink-20 rounded-b-lg p-3">
                    <div className="w-full sm:w-auto flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0">
                      <Button
                        otherClassName="order-last sm:order-first"
                        variant="outlined"
                        func={() => setShowModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="danger"
                        type="submit"
                        func={() => func()}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const Menu = ({isSelection, selected, options}) => {
    const defaultClasses =
      'w-full text-left text-ink-90 p-2 rounded-lg transition duration-200';
    const selectedSelection = 'text-primary-main bg-primary-surface font-bold';
    const disabledClasses =
      'bg-ink-30 border-ink-40 text-ink-60 cursor-not-allowed';
    const otherClasses =
      'hover:bg-ink-20 hover:text-ink-90 focus:bg-ink-20 focus:outline focus:outline-[3px] focus:outline-primary-surface';
    return (
      <div
        className={`absolute top-12 flex flex-col items-start w-full z-10 p-2 bg-white border border-1 border-ink-40 rounded-lg shadow-2`}
      >
        <ul className="text-sm text-gray-700 w-full space-y-1">
          {options.map((res) => (
            <li key={res.value}>
              {res.value === 'delete' ? (
                <Modal res={res} />
              ) : (
                <button
                  onClick={() => handleSelectedMenu(res)}
                  className={`
                    ${res.otherClassName ? res.otherClassName : defaultClasses}
                    ${
                      isSelection &&
                      JSON.stringify(selected) === JSON.stringify(res) &&
                      selectedSelection
                    }
                    ${
                      res.disabled
                        ? disabledClasses
                        : !res.otherClassName && otherClasses
                    }
                  `}
                >
                  {res.label}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className="space-y-3 w-full">
      {label && (
        <label htmlFor={id} className="block text-m font-bold text-ink-100">
          {label}
        </label>
      )}
      <div className={`relative flex flex-col items-center ${otherClassName}`}>
        <Button
          id={id}
          variant={variant}
          otherClassName={width}
          isShadow={false}
          size="dropdown"
          func={() => setIsOpen(!isOpen)}
          disabled={disabled}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">{title}</div>
            <div className="flex items-center justify-end">
              {isOpen ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </div>
          </div>
        </Button>
        {isOpen && (
          <Menu
            selected={selected}
            options={options}
            isSelection={isSelection}
          />
        )}
        {isOpen ? (
          <div
            className="fixed overflow-x-hidden overflow-y-auto inset-0"
            onClick={() => setIsOpen(false)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Dropdown;
