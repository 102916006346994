import {useState} from 'react';
import {Input, Button} from 'components/index';
import * as ActionTypes from 'store/actions';
import * as service from 'services';
import {connect} from 'react-redux';
import {AlertValidation} from 'utils/validation';

const Login = ({onUserLogin}) => {
  const [input, setInput] = useState({
    username: null,
    password: null
  });
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleInputChange = (e) => {
    setInput((prev) => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    const {username, password} = data;

    setAlert(null);
    setAuthLoading(true);

    try {
      const data = await service.Auth.loginByAuth(username, password);
      onUserLogin(data);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setAuthLoading(false);
    }
  };

  return (
    <div className="flex h-screen">
      <div className="m-auto w-full max-w-md p-10 sm:p-14 md:p-16 bg-ink-10 border border-ink-40 rounded-2xl shadow-1">
        <form className="space-y-6" action="#">
          <img className="mb-6" src="./assets/img/logo.png" alt="Logo" />
          {alert}
          <div className="space-y-3">
            <Input id="username" label="Username" func={handleInputChange} />
            <Input
              id="password"
              label="Kata Sandi"
              passIcon
              func={handleInputChange}
            />
          </div>
          <Button
            otherClassName="w-full"
            type="submit"
            func={(e) => handleSubmit(e, input)}
            isLoading={isAuthLoading}
          >
            Masuk
          </Button>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUserLogin: (res) => dispatch({type: ActionTypes.LOGIN_USER, res})
});

export default connect(null, mapDispatchToProps)(Login);
