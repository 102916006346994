import {useState} from 'react';
import {Button, Input} from 'components';
import {useNavigate} from 'react-router-dom';
import * as service from 'services';
import {AlertValidation} from 'utils/validation';

const NewAccountTwitter = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState({
    username: '',
    password: '',
    email_address: '',
    email_password: ''
  });

  const [alert, setAlert] = useState(null);
  const [isCreateLoading, setCreateLoading] = useState(false);

  const handleCreateUser = async (e, data) => {
    e.preventDefault();

    setAlert(null);
    setCreateLoading(true);

    try {
      await service.Settings.Twitter.createNewAccount(data);
      navigate(-1);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setCreateLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const id = (e.target && e.target.id) || e.id;
    const val = (e.target && e.target.value) || e.value || '';

    setValue((prev) => ({
      ...prev,
      [id]: val
    }));
  };

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Buat Baru - Twitter</div>
      <div className="w-full bg-ink-10 p-10 md:px-20 lg:px-32 lg:py-14 xl:px-40 xl:py-16 2xl:px-48 2xl:py-20 rounded-lg shadow-2">
        <form className="space-y-8" action="#">
          <div className="flex flex-col md:flex-row items-center w-full space-x-0 space-y-8 md:space-x-16 md:space-y-0">
            <div className="flex flex-col w-full space-y-8">
              <Input
                id="username"
                label="Username"
                value={value.username}
                func={handleInputChange}
              />
              <Input
                id="password"
                label="Kata Sandi"
                passIcon
                value={value.password}
                func={handleInputChange}
              />
            </div>
            <div className="flex flex-col w-full space-y-8">
              <Input
                id="email_address"
                label="Email"
                value={value.email_address}
                func={handleInputChange}
              />
              <Input
                id="email_password"
                label="Kata Sandi Email"
                passIcon
                value={value.email_password}
                func={handleInputChange}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-end">
            <div className="w-full sm:w-auto flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0">
              <Button
                otherClassName="order-last sm:order-first"
                variant="outlined"
                func={() => navigate(-1)}
              >
                Batal
              </Button>
              <Button
                type="submit"
                func={(e) => handleCreateUser(e, value)}
                isLoading={isCreateLoading}
              >
                Simpan
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccountTwitter;
