import dayjs from 'dayjs';
import 'dayjs/locale/id';

// Change string to 000.000,00
export const thousandFormatter = (value) => {
  return value.toLocaleString('id', {maximumFractionDigits: 2});
};

// Change string to DD/MM/YY
export const dateFormatter = (cell) => {
  return new Date(cell).toLocaleString('id-ID', {
    dateStyle: 'short'
  });
};

export const dateTimeFormatter = (cell) => {
  return dayjs(cell, 'YYYY-MM-DD HH:mm:ss')
    .locale('id')
    .format('D MMM YYYY HH:mm');
};

export const thousandSeparator = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const convert_number_str = (numberStr) => {
  let number = parseInt(numberStr, 10);
  if (number >= 1000000) {
    number = number / 1000000;
    number = parseFloat(number.toFixed(3));
    numberStr = number.toString().replace('.', ',');

    if (number >= 1000) {
      number = thousandSeparator(numberStr);
    } else {
      number = numberStr;
    }

    return number + 'M';
  }
  return thousandSeparator(numberStr);
};
