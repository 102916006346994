import axios from 'utils/axios';

export const getAllUser = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null,
  search_query = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get('/users', {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order,
          search_query
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const createNewUser = (res) => {
  const {username, password, password_confirmation} = res;
  return new Promise((resolve, reject) =>
    axios
      .post('/users', {
        username,
        password,
        password_confirmation
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getUserById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/users/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const updateUserById = (res) => {
  const {id, username, password, password_confirmation} = res;
  return new Promise((resolve, reject) =>
    axios
      .put(`/users/${id}`, {
        username,
        password,
        password_confirmation
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const deleteUserById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .delete(`/users/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
