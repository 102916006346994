import {Spinner} from 'flowbite-react';

const sizes = {
  no: 'p-0',
  md: 'px-4 py-2 rounded-md',
  lg: 'px-5 py-3 rounded-lg',
  dropdown: 'px-2.5 py-2 rounded-lg'
};

const cursor = {
  pointer: 'cursor-pointer',
  wait: 'cursor-wait',
  notAllowed: 'cursor-not-allowed'
};

const variants = {
  primary:
    'bg-primary-main text-ink-10 hover:bg-primary-hover focus:outline focus:outline-primary-focus focus:outline-[3px] focus:bg-primary-hover active:outline-0 active:bg-primary-pressed disabled:text-ink-60 disabled:bg-ink-30 disabled:outline disabled:outline-ink-40 disabled:outline-1',
  secondary:
    'bg-secondary-main text-ink-10 hover:bg-secondary-hover focus:outline focus:outline-secondary-focus focus:outline-[3px] focus:bg-secondary-hover active:outline-0 active:bg-secondary-pressed disabled:text-ink-60 disabled:bg-ink-30 disabled:outline disabled:outline-ink-40 disabled:outline-1',
  outlined:
    'border bg-ink-10 text-ink-100 border-ink-40 hover:bg-ink-20 focus:outline focus:outline-primary-focus focus:outline-[3px] focus:bg-ink-20 focus:border-primary-main active:outline-0 active:bg-ink-30 active:border-ink-50 disabled:border-0 disabled:text-ink-60 disabled:bg-ink-30 disabled:outline disabled:outline-ink-40 disabled:outline-1',
  danger:
    'bg-danger-main text-ink-10 hover:bg-danger-hover focus:outline focus:outline-danger-focus focus:outline-[3px] focus:bg-danger-hover active:outline-0 active:bg-danger-pressed disabled:text-ink-60 disabled:bg-ink-30 disabled:outline disabled:outline-ink-40 disabled:outline-1',
  text: 'text-left text-primary-main hover:underline hover:underline-offset-[3px] focus:text-primary-hover active:outline-0 active:text-primary-pressed disabled:text-ink-60',
  warning:
    'bg-warning-main text-ink-10 hover:bg-warning-hover focus:outline focus:outline-warning-focus focus:outline-[3px] focus:bg-warning-hover active:outline-0 active:bg-warning-pressed disabled:text-ink-60 disabled:bg-ink-30 disabled:outline disabled:outline-ink-40 disabled:outline-1',
  outlinedWarning:
    'bg-warning-surface text-warning-main hover:bg-warning-border focus:outline focus:outline-warning-focus focus:outline-[3px] focus:bg-warning-border active:outline-0 active:bg-warning-pressed active:text-ink-10 disabled:text-ink-60 disabled:bg-ink-30 disabled:outline disabled:outline-ink-40 disabled:outline-1',
  dropdown:
    'border bg-ink-10 text-ink-100 border-ink-50 hover:border-primary-main focus:border-primary-main focus:outline focus:outline-[3px] focus:outline-primary-focus focus:outline-offset-0 focus:text-ink-100 disabled:bg-ink-30 disabled:border-ink-50'
};

const Button = ({
  variant = 'primary',
  size = 'md',
  disabled = false,
  icon,
  children,
  isLoading,
  isShadow = true,
  func = () => {},
  otherClassName,
  ...otherProps
}) => {
  let variantClasses = variants[variant];
  let sizeClasses = sizes[size];
  let cursorClasses = cursor[disabled ? 'notAllowed' : 'pointer'];
  let shadowClasses =
    variant !== 'text' && isShadow ? 'shadow-1' : 'shadow-none';
  let fontClasses =
    variant !== 'dropdown' && variant !== 'text' ? 'font-bold' : 'font-regular';
  let fontTextClasses = variant !== 'text' && 'text-m';

  return (
    <button
      type="button"
      className={`${fontTextClasses} ${fontClasses} ${shadowClasses} ${cursorClasses} ${sizeClasses} ${variantClasses} ${otherClassName}`}
      disabled={disabled}
      {...otherProps}
      onClick={func}
      {...otherProps}
    >
      <div className="flex items-center justify-center w-full">
        {icon && <span className="mr-2">{icon}</span>}
        {children}
        {isLoading && (
          <Spinner
            className="ml-3 fill-primary-main dark:text-ink-40"
            aria-label="Loading"
            size="sm"
          />
        )}
      </div>
    </button>
  );
};

export default Button;
