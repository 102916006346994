import {CardButton} from 'components';
import {useNavigate} from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <div className="text-h-l">Pengaturan</div>
      <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 text-center">
        <div className="flex items-center flex-col gap-3">
          <div className="flex items-center flex-col md:flex-row w-full gap-3">
            <CardButton func={() => navigate('instagram')}>
              Instagram
            </CardButton>
            <CardButton func={() => navigate('twitter')}>
              Twitter (X)
            </CardButton>
          </div>
          <div className="flex items-center flex-col md:flex-row w-full gap-3">
            <CardButton func={() => navigate('facebook')}>Facebook</CardButton>
            <CardButton func={() => navigate('tiktok')}>TikTok</CardButton>
          </div>
          <div className="flex items-center flex-col md:flex-row w-full gap-3">
            <div className="w-full md:w-1/2">
              <CardButton func={() => navigate('proxy')}>Proxy</CardButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
