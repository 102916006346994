import {useState, useEffect} from 'react';
import {Spinner} from 'flowbite-react';
import {Button, Modal, Table} from 'components';
import {PencilSquareIcon, TrashIcon} from '@heroicons/react/24/outline';
import {PlusIcon} from '@heroicons/react/20/solid';

import {useNavigate} from 'react-router-dom';
import * as service from 'services';
import {validationStatus, AlertValidation} from 'utils/validation';
import {paginationSizeOptions} from 'utils/paginationOptions';

const iconClasses = 'h-5 w-5';

const ActionFormatter = ({row, handleDelete, handleEdit}) => {
  const {username, id} = row;

  return (
    <div className="flex gap-1 items-stretch justify-center flex-shrink-0">
      <Button variant="secondary" func={() => handleEdit(id)}>
        <PencilSquareIcon className={iconClasses} />
      </Button>
      <Modal
        variant="danger"
        title="Hapus Pengguna"
        btnTitle={<TrashIcon className={iconClasses} />}
        btmRightBtnTitle="Hapus"
        btmLeftBtnTitle="Batal"
        btmRightBtnFunc={() => handleDelete(row)}
        xIcon
      >
        {`Anda yakin ingin menghapus ${username}?`}
      </Modal>
    </div>
  );
};

const SettingsFacebook = () => {
  const navigate = useNavigate();
  const [dataColumns, setDataColumns] = useState([]);
  const [alert, setAlert] = useState();
  const [isFetch, setFetch] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    size: paginationSizeOptions[0],
    total: 1,
    count: 1
  });

  const fetchAccountData = async (page, size) => {
    const sizing = (size && size.value) || pagination.size.value;

    const paging = page || 1;
    const numbering = (paging - 1) * sizing;

    try {
      const data = await service.Settings.Facebook.getAllAccount(
        paging,
        sizing,
        null,
        null
      );
      const newData = data.data.map((res, i) => ({
        no: numbering + i + 1,
        ...res
      }));
      setDataColumns(newData);
      setPagination((prev) => ({
        ...prev,
        current: data.page_number,
        total: data.total_pages,
        count: data.count
      }));
      setFetch(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  const deleteAccountById = async (row) => {
    const {id, username} = row;

    try {
      await service.Settings.Facebook.deleteAccountById(id);
      await fetchAccountData();
      setAlert(
        AlertValidation(`Akun '${username}' berhasil dihapus!`, 'success')
      );
    } catch (err) {
      setAlert(
        AlertValidation(
          `Akun '${username}' gagal dihapus! Mohon ulangi lagi! (Error: ${validationStatus(
            err
          )})`
        )
      );
    }
  };

  const handleEditById = (id) => {
    navigate(`${id}/edit`);
  };

  const columns = [
    {
      dataField: 'no',
      text: 'No',
      headerAlign: 'center'
    },
    {
      dataField: 'username',
      text: 'Username'
    },
    {
      dataField: 'action',
      text: 'Aksi',
      headerAlign: 'center',
      formatter: (row) => (
        <ActionFormatter
          row={row}
          handleDelete={deleteAccountById}
          handleEdit={handleEditById}
        />
      )
    }
  ];

  const handlePagination = (page) => {
    setAlert(null);

    setPagination((prev) => ({
      ...prev,
      current: page
    }));

    fetchAccountData(page);
  };

  const handleShowEntries = (entries) => {
    const {value} = entries;
    setAlert(null);

    setPagination((prev) => ({
      ...prev,
      size: value
    }));

    fetchAccountData(null, value);
  };

  useEffect(() => {
    fetchAccountData();
  }, []);

  const tableUser = (
    <Table
      columns={columns}
      data={dataColumns}
      page={pagination}
      handlePagination={handlePagination}
      handleShowEntries={handleShowEntries}
      leftUpper={
        <Button
          icon={<PlusIcon className={iconClasses} />}
          onClick={() => navigate('new-account-facebook')}
        >
          Buat Baru
        </Button>
      }
    />
  );

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Facebook</div>
      <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 text-center">
        {isFetch ? (
          <Spinner
            className="mr-3 fill-primary-main dark:text-ink-40"
            aria-label="Loading"
            size="lg"
          />
        ) : (
          tableUser
        )}
      </div>
    </div>
  );
};

export default SettingsFacebook;
