import * as ActionTypes from 'store/actions';

const initialState = {
  isLoggedIn: !!sessionStorage.getItem('token'),
  token: sessionStorage.getItem('token'),
  currentUser: sessionStorage.getItem('current_user'),
  isAdmin: JSON.parse(sessionStorage.getItem('is_admin'))
};

const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.LOGIN_USER) {
    const {username, token, is_admin} = action.res;
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('is_admin', JSON.stringify(is_admin));
    sessionStorage.setItem('current_user', username);

    return {
      ...state,
      isLoggedIn: true,
      token: token,
      isAdmin: is_admin,
      currentUser: username
    };
  }

  if (action.type === ActionTypes.LOGOUT_USER) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('is_admin');
    sessionStorage.removeItem('current_user');
    return {
      ...state,
      isLoggedIn: false,
      token: null,
      currentUser: null,
      isAdmin: null
    };
  }

  if (action.type === ActionTypes.LOAD_USER) {
    const currentUser = JSON.parse(sessionStorage.getItem('current_user'));
    const isAdmin = JSON.parse(sessionStorage.getItem('is_admin'));

    return {
      ...state,
      currentUser: currentUser,
      isAdmin: isAdmin
    };
  }

  return {...state};
};

export default reducer;
