import {useState} from 'react';
import {Button, Input} from 'components';
import {useNavigate} from 'react-router-dom';
import * as service from 'services';
import {AlertValidation} from 'utils/validation';

const NewTokenTiktok = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState('');

  const [alert, setAlert] = useState(null);
  const [isCreateLoading, setCreateLoading] = useState(false);

  const handleCreateUser = async (data) => {
    setAlert(null);
    setCreateLoading(true);

    try {
      await service.Settings.TikTok.createNewToken(data);
      navigate(-1);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setCreateLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Buat Baru - TikTok</div>
      <div className="w-full bg-ink-10 p-10 md:px-20 lg:px-32 lg:py-14 xl:px-40 xl:py-16 2xl:px-48 2xl:py-20 rounded-lg shadow-2 space-y-8">
        <Input
          id="token"
          label="Token"
          value={value}
          func={(e) => setValue(e.target.value)}
        />
        <div className="flex flex-col sm:flex-row items-center justify-end">
          <div className="w-full sm:w-auto flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0">
            <Button
              otherClassName="order-last sm:order-first"
              variant="outlined"
              func={() => navigate(-1)}
            >
              Batal
            </Button>
            <Button
              type="submit"
              func={() => handleCreateUser(value)}
              isLoading={isCreateLoading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTokenTiktok;
