import {useState, useEffect} from 'react';
import {Spinner} from 'flowbite-react';
import {
  Badge,
  Button,
  Checkbox,
  Modal,
  Table,
  Selection,
  DatePicker,
  Dropdown
} from 'components';
import {TrashIcon, FunnelIcon} from '@heroicons/react/24/outline';
import {PlusIcon} from '@heroicons/react/20/solid';
import {useNavigate, useParams} from 'react-router-dom';
import * as service from 'services';
import {validationStatus, AlertValidation} from 'utils/validation';
import {dateFormatter} from 'utils/formatter';
import {paginationSizeOptions} from 'utils/paginationOptions';
import {options, topicOptions} from 'utils/topicOptions';
import {connect} from 'react-redux';

const iconClasses = 'h-5 w-5';

const ActionFormatter = ({row, handleDelete, handleResult, is_auth}) => {
  const {created_by_username, topic_name, id} = row;

  return (
    <div className="flex gap-1 items-stretch justify-center flex-shrink-0">
      <Button variant="secondary" func={() => handleResult(id)}>
        Lihat Hasil
      </Button>
      {is_auth && (
        <Modal
          variant="danger"
          title="Hapus Pengguna"
          btnTitle={<TrashIcon className={iconClasses} />}
          btmRightBtnTitle="Hapus"
          btmLeftBtnTitle="Batal"
          btmRightBtnFunc={() => handleDelete(row)}
          xIcon
        >
          {`Anda yakin ingin menghapus ${topic_name} - ${created_by_username}?`}
        </Modal>
      )}
    </div>
  );
};

const Topics = ({is_admin}) => {
  const {topicId} = useParams();
  const navigate = useNavigate();
  const [dataColumns, setDataColumns] = useState([]);
  const [alert, setAlert] = useState();
  const [isFetch, setFetch] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    size: paginationSizeOptions[0],
    total: 1,
    count: 1
  });
  const [topicData, setTopicData] = useState();

  const [searchQuery, setSearchQuery] = useState('');
  const [isCheckedHeader, setCheckedHeader] = useState(false);
  const [deleteTopicId, setDeleteTopicId] = useState([]);

  const [selection, setSelection] = useState({
    status: [
      {
        value: 'finished',
        label: 'Selesai'
      },
      {
        value: 'processing',
        label: 'Proses'
      }
    ],
    created_by: []
  });

  const [filterValue, setFilterValue] = useState({
    sources: null,
    status: null,
    created_by: null,
    created_at_start: null,
    created_at_end: null
  });

  const [tempFilterValue, setTempFilterValue] = useState({
    sources: null,
    status: null,
    created_by: null,
    created_at_start: null,
    created_at_end: null
  });

  const fetchUserData = async () => {
    try {
      const {data} = await service.UserManagement.getAllUser(
        null,
        null,
        null,
        null,
        null
      );
      const newData = data.map(({id: value, username: label}) => ({
        value,
        label
      }));
      setSelection((prev) => ({
        ...prev,
        created_by: newData
      }));
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  const fetchTokenData = async (page, searchData, size, filterData) => {
    filterData && searchData === null && setSearchQuery('');

    const searchQueryData = searchData === '' ? null : searchQuery;
    const search = searchData || searchQueryData;
    const sizing = (size && size.value) || pagination.size.value;
    const filter = filterData || filterValue;

    const paging = page || 1;
    const numbering = (paging - 1) * sizing;

    try {
      const data = await service.CaseManagement.getCaseById(
        topicId,
        paging,
        sizing,
        null,
        null,
        search,
        filter.sources && filter.sources.flatMap((res) => res.value).toString(),
        filter.status && filter.status.value,
        filter.created_by && filter.created_by.value,
        filter.created_at_start,
        filter.created_at_end
      );
      const newData = data.topics.data.map((res, i) => ({
        no: numbering + i + 1,
        created_by_username: res.created_by.username,
        status: res.is_finished ? 'Selesai' : 'Proses',
        is_checked: false,
        ...res
      }));
      setTopicData(data.name);
      setDeleteTopicId([]);
      setCheckedHeader(false);
      setDataColumns(newData);
      setPagination((prev) => ({
        ...prev,
        current: data.topics.page_number,
        total: data.topics.total_pages,
        count: data.topics.count
      }));
      setFetch(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  const deleteTopicById = async (row) => {
    const {id, topic_name, created_by_username} = row;

    try {
      await service.Topics.deleteTopicById(id);
      await fetchTokenData();
      setAlert(
        AlertValidation(
          `Keyword '${topic_name}' - '${created_by_username}' berhasil dihapus!`,
          'success'
        )
      );
    } catch (err) {
      setAlert(
        AlertValidation(
          `Keyword '${topic_name}' - '${created_by_username}' gagal dihapus! Mohon ulangi lagi! (Error: ${validationStatus(
            err
          )})`
        )
      );
    }
  };

  const handleDeleteAll = async () => {
    try {
      const promise = await Promise.allSettled(
        deleteTopicId.map(
          async (res) => await service.Topics.deleteTopicById(res)
        )
      );
      await fetchTokenData();
      const checkError = promise.find((res) => res.status === 'rejected');
      if (checkError) {
        throw Error;
      } else {
        setAlert(
          AlertValidation('Keyword yang dipilih berhasil dihapus!', 'success')
        );
      }
    } catch (err) {
      setAlert(
        AlertValidation(
          'Terdapat keyword yang gagal dihapus! Mohon diperiksa kembali dan ulangi lagi!'
        )
      );
    }
  };

  const handleResultById = (id) => {
    navigate(`${id}/result`);
  };

  const statusFormatter = (cell, row) => {
    const {is_finished} = row;
    const variant = is_finished ? 'success' : 'secondary';

    return (
      <div className="flex items-stretch justify-center flex-shrink-0">
        <Badge variant={variant}>{cell}</Badge>
      </div>
    );
  };

  const sourceFormatter = (value) => {
    let etc = '';
    const source = value.map((res) => {
      return options.find((topic) => res === topic.value).label;
    });

    if (source.length > 3) {
      etc = ', dan lain-lain.';
    }

    return source.slice(0, 3).join(', ') + etc;
  };

  const handleCheckedHeader = (id, status) => {
    let newData = [...dataColumns];
    newData = newData.map((res) => ({
      ...res,
      is_checked: status
    }));
    setDataColumns(newData);
    if (status) {
      setDeleteTopicId([...newData.map((res) => res.id)]);
    } else {
      setDeleteTopicId([]);
    }

    setCheckedHeader(status);
  };

  const handleCheckbox = (id, status) => {
    let data = [...dataColumns];
    const index = data.findIndex((res) => res.id === id);
    data[index].is_checked = status;
    if (status) {
      setDeleteTopicId([...deleteTopicId, id]);
    } else {
      const topicDeleted = deleteTopicId.filter((res) => res !== id);
      setCheckedHeader(false);
      setDeleteTopicId(topicDeleted);
    }
    setDataColumns(data);
  };

  const checkboxFormatter = (cell, row) => {
    const {is_checked} = row;

    return <Checkbox id={cell} checked={is_checked} func={handleCheckbox} />;
  };

  const columns = [
    {
      dataField: 'id',
      type: 'checkbox',
      formatter: checkboxFormatter,
      is_checked: isCheckedHeader,
      func: handleCheckedHeader
    },
    {
      dataField: 'no',
      text: 'No',
      headerAlign: 'center'
    },
    {
      dataField: 'created_at',
      text: 'Dibuat Pada',
      formatter: dateFormatter
    },
    {
      dataField: 'created_by_username',
      text: 'Dibuat Oleh'
    },
    {
      dataField: 'topic_name',
      text: 'Keyword'
    },
    {
      dataField: 'sources',
      text: 'Sumber',
      formatter: sourceFormatter
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      formatter: statusFormatter
    },
    {
      dataField: 'action',
      text: 'Aksi',
      headerAlign: 'center',
      formatter: (row) => (
        <ActionFormatter
          row={row}
          handleDelete={deleteTopicById}
          handleResult={handleResultById}
          is_auth={is_admin}
        />
      )
    }
  ];

  const handlePagination = (page) => {
    setAlert(null);

    setPagination((prev) => ({
      ...prev,
      current: page
    }));

    fetchTokenData(page);
  };

  const handleShowEntries = (entries) => {
    const {value} = entries;
    setAlert(null);

    setPagination((prev) => ({
      ...prev,
      size: value
    }));

    fetchTokenData(null, null, value);
  };

  const handleSearch = (query) => {
    fetchTokenData(null, query);
    setSearchQuery(query);
  };

  const handleFilter = (filter) => {
    fetchTokenData(null, null, null, filter);
    setTempFilterValue(filter);
  };

  const handleResetFilter = () => {
    const resetValue = {
      sources: null,
      status: null,
      created_by: null,
      created_at_start: null,
      created_at_end: null
    };
    setFilterValue(resetValue);
    setTempFilterValue(resetValue);
    fetchTokenData(null, null, null, resetValue);
  };

  const handleCloseFilter = () => {
    setFilterValue(tempFilterValue);
  };

  useEffect(() => {
    is_admin && fetchUserData();
    fetchTokenData();
  }, []);

  const handleInputChange = (e) => {
    const id = (e.target && e.target.id) || e.id;
    const val = (e.target && e.target.value) || e.value || '';

    setFilterValue((prev) => ({
      ...prev,
      [id]: val
    }));
  };

  const filterContainer = (
    <div className="mt-6 mb-3 space-y-6">
      <Selection
        id="sources"
        label="Sumber"
        value={filterValue.sources}
        options={topicOptions}
        func={handleInputChange}
        multiple
        searchable
      />
      <Dropdown
        id="status"
        value={filterValue.status}
        options={selection.status}
        func={handleInputChange}
        label="Status"
        isSelection
      />
      <div className="flex flex-col md:flex-row items-center justify-between space-x-0 space-y-6 md:space-x-3 md:space-y-0">
        <DatePicker
          id="created_at_start"
          label="Tanggal Awal"
          value={filterValue.created_at_start}
          func={handleInputChange}
        />
        <DatePicker
          id="created_at_end"
          label="Tanggal Akhir"
          value={filterValue.created_at_end}
          func={handleInputChange}
        />
      </div>
      {is_admin && (
        <Dropdown
          id="created_by"
          value={filterValue.created_by}
          options={selection.created_by}
          func={handleInputChange}
          label="Dibuat Oleh"
          isSelection
        />
      )}
    </div>
  );

  const tableUser = (
    <Table
      columns={columns}
      data={dataColumns}
      page={pagination}
      searchable
      searchQuery={searchQuery}
      handlePagination={handlePagination}
      handleShowEntries={handleShowEntries}
      handleSearchQuery={handleSearch}
      leftUpper={
        <Button
          icon={<PlusIcon className={iconClasses} />}
          onClick={() => navigate('new-keyword')}
        >
          Buat Keyword
        </Button>
      }
      rightUpper={
        <>
          {deleteTopicId.length > 0 && (
            <Modal
              variant="danger"
              title="Hapus Pengguna"
              btnTitle={
                <div className="flex flex-row">
                  <TrashIcon className={`mr-2 ${iconClasses}`} />
                  Hapus Semua
                </div>
              }
              btmRightBtnTitle="Hapus"
              btmLeftBtnTitle="Batal"
              btmRightBtnFunc={() => handleDeleteAll()}
              xIcon
            >
              {`Anda yakin ingin menghapus keyword yang terpilih?`}
            </Modal>
          )}
          <Modal
            variant="outlined"
            title="Filter"
            btnTitle={
              <div className="flex flex-row">
                <FunnelIcon className={`mr-2 ${iconClasses}`} />
                Filter
              </div>
            }
            btmRightBtnTitle="Filter"
            btmLeftBtnTitle="Reset"
            btmLeftBtnFunc={handleResetFilter}
            btmBtnBetween
            btmRightBtnVariant="primary"
            btmRightBtnFunc={() => handleFilter(filterValue)}
            btnCloseFunc={handleCloseFilter}
            modalSize="md"
            xIcon
          >
            {filterContainer}
          </Modal>
        </>
      }
    />
  );

  return (
    <div className="space-y-6">
      {alert}
      <div className="flex flex-row items-center gap-2">
        <div className="text-h-l">Daftar Keyword</div>
        {!isFetch && <div className="text-h-m">- [{topicData}]</div>}
      </div>
      <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 text-center">
        {isFetch ? (
          <Spinner
            className="mr-3 fill-primary-main dark:text-ink-40"
            aria-label="Loading"
            size="lg"
          />
        ) : (
          tableUser
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  is_admin: state.auth.isAdmin
});

export default connect(mapStateToProps, null)(Topics);
