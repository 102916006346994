import {Chart as ChartJS, registerables} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(...registerables);

const GraphLineChart = ({
  yAxisTitle,
  y1Axis = false,
  y1AxisTitle,
  noY1AxisGrid,
  noPoint,
  noXGrid,
  noYGrid,
  data
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      }
    },
    elements: {
      line: {
        cubicInterpolationMode: 'monotone'
      },
      ...(noPoint && {
        point: {
          radius: 0
        }
      })
    },
    scales: {
      x: {
        ...(noXGrid && {
          grid: {
            display: false
          }
        })
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ...(yAxisTitle && {
          title: {
            display: true,
            text: yAxisTitle
          }
        }),
        ...(noYGrid && {
          grid: {
            display: false
          }
        }),
        beginAtZero: true,
        min: 0
      },
      ...(y1Axis && {
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          ...(y1AxisTitle && {
            title: {
              display: true,
              text: y1AxisTitle
            }
          }),
          beginAtZero: true,
          min: 0,
          // grid line settings
          ...(noY1AxisGrid && {
            grid: {
              drawOnChartArea: false // only want the grid lines for one axis to show up
            }
          })
        }
      })
    },
    maintainAspectRatio: false
  };

  return <Line options={options} data={data} height="300px" />;
};

export default GraphLineChart;
