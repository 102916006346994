import {useState, useEffect} from 'react';
import {
  ChevronDownIcon,
  Bars3BottomLeftIcon,
  ArrowRightOnRectangleIcon,
  KeyIcon
} from '@heroicons/react/20/solid';
import * as ActionTypes from 'store/actions';
import {connect} from 'react-redux';
import {Avatar, Modal} from 'components/index';
import {generateLicenseCode} from 'utils/license-generator';
import * as service from 'services';

const BurgerMenu = ({handleSidebar = () => {}}) => {
  return (
    <button
      onClick={handleSidebar}
      type="button"
      className="
        inline-flex items-center mr-2 p-2 text-sm text-ink-60 rounded-lg
        hover:text-ink-70 focus:outline-none focus:ring-[3px] focus:ring-primary-focus"
    >
      <span className="sr-only">Open Sidebar</span>
      <Bars3BottomLeftIcon className="h-6 w-6" />
    </button>
  );
};

const Menu = ({func = () => {}, children, icon}) => {
  return (
    <div className="w-full">
      <div className="py-1.5 mx-1.5">
        <button
          type="button"
          onClick={func}
          className="
          flex items-center px-4 py-2 text-sm text-ink-90 rounded-lg w-full
          hover:bg-danger-surface hover:text-danger-main active:bg-danger-main active:text-ink-10"
        >
          {icon}
          <span className="flex-1 ml-3 whitespace-nowrap text-m text-left">
            {children}
          </span>
        </button>
      </div>
    </div>
  );
};

const DropdownMenu = ({handleLogout, user, license}) => {
  const [isOpen, setOpen] = useState(false);
  const username = user || 'Admin';

  return (
    <div className="flex items-center">
      <button
        id="dropdownAvatarNameButton"
        data-dropdown-toggle="dropdown-user"
        className="
          flex items-center text-sm font-medium text-ink-90 rounded-full
          hover:text-primary-main md:mr-0 focus:outline focus:outline-[3px] focus:outline-primary-focus focus:outline-offset-2"
        type="button"
        data-dropdown-offset-distance="-110"
        data-dropdown-offset-skidding="55"
        data-dropdown-placement="right"
        onClick={() => setOpen(!isOpen)}
      >
        <span className="sr-only">Open user menu</span>
        <Avatar name={username} size="sm" otherClassName="mr-2" />
        {username}
        <ChevronDownIcon className="h-4 w-4 mx-1.5" />
      </button>
      {isOpen && (
        <div
          className="absolute top-12 right-5 flex flex-col items-start z-50 my-4 text-base list-none bg-white rounded-lg shadow-1"
          id="dropdown-user"
        >
          <Modal
            variant="danger"
            title="Lisensi"
            btnTitle="Lisensi"
            btmRightBtnTitle="Tutup"
            icon={<KeyIcon className="h-6 w-6" />}
            xIcon
            isBtmBtnLeft={false}
            btnCustomize
          >
            <div className="flex flex-col text-m space-y-3">
              <div>
                <span className="text-h-s font-bold">
                  {license.number_of_users}
                </span>
                /{license.maximum_users_limit} pengguna aktif
              </div>
              <div className="flex flex-col space-y-1">
                <div>
                  <span className="font-bold mr-1">Lisensi Utama:</span>
                  {license.executive_dashboard_license === ''
                    ? '-'
                    : license.executive_dashboard_license}
                </div>
                <div>
                  <span className="font-bold mr-1">Lisensi Pengguna:</span>
                  {generateLicenseCode(username)}
                </div>
                <div>
                  <span className="font-bold mr-1">
                    Social Media Listening Engine and Analytics Tools:
                  </span>
                  {license.social_media_engine === ''
                    ? '-'
                    : license.social_media_engine}
                </div>
                <div>
                  <span className="font-bold mr-1">
                    Online Media Listening Engine and Analytics Tools:
                  </span>
                  {license.online_media_engine === ''
                    ? '-'
                    : license.online_media_engine}
                </div>
                <div>
                  <span className="font-bold mr-1">Sentiment Analytics:</span>
                  {license.sentiment_analysis === ''
                    ? '-'
                    : license.sentiment_analysis}
                </div>
                <div>
                  <span className="font-bold mr-1">
                    Social Network Analysis:
                  </span>
                  {license.social_network_analysis === ''
                    ? '-'
                    : license.social_network_analysis}
                </div>
                <div>
                  <span className="font-bold mr-1">
                    Account & Influencer Identification Analysis:
                  </span>
                  {license.account_identification_analysis === ''
                    ? '-'
                    : license.account_identification_analysis}
                </div>
              </div>
              {license.is_activated ? (
                <a
                  className="text-primary-main hover:underline hover:underline-offset-[3px] focus:outline focus:outline-primary-focus focus:outline-[3px] focus:text-primary-hover active:outline-0 active:text-primary-pressed disabled:text-ink-60"
                  href={'./assets/license/social-listening.pdf'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Aktif
                </a>
              ) : (
                <div className="font-bold">Uji Coba</div>
              )}
            </div>
          </Modal>
          <div className="w-full px-2">
            <div className="border-ink-40 border-[0.25px] w-full" />
          </div>
          <Menu
            icon={<ArrowRightOnRectangleIcon className="h-4 w-4" />}
            func={handleLogout}
          >
            Keluar
          </Menu>
        </div>
      )}
      {isOpen ? (
        <div
          className="fixed overflow-x-hidden overflow-y-auto inset-0"
          onClick={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
};

const Header = ({handleSidebar = () => {}, sidebar, onUserLogout, user}) => {
  const [data, setData] = useState();

  const handleLogout = () => {
    onUserLogout();
  };

  const fetchLicenseData = async () => {
    try {
      const data = await service.Settings.License.getAllLicense();
      setData(data);
    } finally {
      undefined;
    }
  };

  useEffect(() => {
    fetchLicenseData();
  }, []);

  return (
    <nav
      className={`
      fixed top-0 z-40 transition-all w-full
      ${
        sidebar
          ? 'sm:left-64 sm:w-[calc(100%-16rem)]'
          : 'sm:left-20 sm:w-[calc(100%-5rem)]'
      }
      bg-ink-10
    `}
    >
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <BurgerMenu handleSidebar={handleSidebar} />
          </div>
          <DropdownMenu
            handleLogout={handleLogout}
            user={user}
            license={data}
          />
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({type: ActionTypes.LOGOUT_USER})
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
