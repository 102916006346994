const BackDrop = ({handleClose = () => {}}) => {
  return (
    <div
      className="transition bg-ink-90 bg-opacity-50 fixed inset-0 z-30"
      onClick={handleClose}
    ></div>
  );
};

export default BackDrop;
