import Select from 'react-tailwindcss-select';

const textClasses = 'text-m';

const Selection = ({
  id,
  options,
  value,
  label,
  func = () => {},
  disabled = false,
  multiple = false,
  searchable = false,
  clearable = false,
  placeholder = 'Pilih',
  searchInputPlaceholder = 'Cari',
  otherClassName
}) => {
  const handleInput = (e) => {
    func({
      id: id,
      value: e === null ? [] : e
    });
  };

  return (
    <div className="space-y-3 w-full">
      {label && (
        <label htmlFor={id} className="block text-m font-bold text-ink-100">
          {label}
        </label>
      )}
      <div className={`relative flex flex-col items-center ${otherClassName}`}>
        <Select
          isDisabled={disabled}
          isMultiple={multiple}
          isSearchable={searchable}
          isClearable={clearable}
          value={value}
          placeholder={placeholder}
          searchInputPlaceholder={searchInputPlaceholder}
          onChange={(e) => handleInput(e)}
          options={options}
          classNames={{
            menuButton: ({isDisabled}) =>
              `flex ${textClasses} bg-ink-10 border border-ink-50 rounded-lg shadow-1 ${
                value ? 'text-ink-100' : 'text-ink-70'
              } ${
                isDisabled
                  ? 'bg-ink-30 border-ink-40 text-ink-60'
                  : 'hover:border-primary-main focus:border-primary-main focus:outline focus:outline-[3px] focus:outline-primary-focus focus:text-ink-100 active:font-bold active:bg-ink-10 active:text-ink-100'
              }`,
            menu: `absolute z-10 w-full ${textClasses} bg-white shadow-2 border border-ink-40 rounded-lg py-1 mt-1.5 text-ink-90`,
            listItem: ({isSelected}) =>
              `block transition duration-200 p-2 cursor-pointer select-none truncate rounded-lg ${
                isSelected
                  ? `text-primary-main bg-primary-surface font-bold`
                  : `text-ink-90 hover:bg-ink-20 hover:text-ink-90 focus:bg-ink-20 focus:outline focus:outline-[3px] focus:outline-primary-surface`
              }`,
            listDisabledItem:
              'p-2 cursor-not-allowed truncate text-ink-60 select-none',
            searchBox: `w-full py-2 pl-8 ${textClasses} text-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0 focus:outline-none`,
            tagItemIconContainer:
              'flex items-center px-1 cursor-pointer rounded-r-lg hover:text-primary-main hover:font-bold',
            tagItem: () =>
              `bg-primary-surface border rounded-lg flex space-x-1 pl-1`,
            tagItemText: 'text-primary-main font-bold',
            closeIcon: 'w-5 h-5 p-0.5 text-ink-90'
          }}
        />
      </div>
    </div>
  );
};

export default Selection;
