import {Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

const PrivateRoute = ({Component, isLoggedIn}) => {
  const location = useLocation();

  return isLoggedIn ? (
    <Component />
  ) : (
    <Navigate
      replace
      to={{
        pathname: '/login',
        state: {from: location}
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PrivateRoute);
