export const paginationSizeOptions = [
  {
    value: 10,
    label: 10
  },
  {
    value: 25,
    label: 25
  },
  {
    value: 50,
    label: 50
  },
  {
    value: 75,
    label: 75
  },
  {
    value: 100,
    label: 100
  }
];
