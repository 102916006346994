import {Button} from 'components';
import {useNavigate} from 'react-router-dom';

const Undefined = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-ink-10 p-10 rounded-lg shadow-2 space-y-3 text-center">
      <div className="flex items-center flex-col space-y-16">
        <div className="text-h-m">Halaman Tidak Ditemukan</div>
        <Button func={() => navigate('/', {replace: true})}>
          Kembali ke Beranda
        </Button>
      </div>
    </div>
  );
};

export default Undefined;
