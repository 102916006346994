import {useState, useEffect} from 'react';
import {Dropdown, Input, Modal} from 'components';
import {Spinner} from 'flowbite-react';
import {paginationSizeOptions} from 'utils/paginationOptions';
import {
  FolderIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/20/solid';

const DropdownMenu = ({
  data,
  handleEdit = () => {},
  handleDelete = () => {}
}) => {
  const [isOpen, setOpen] = useState(false);
  const {id, name, description, created_by} = data;
  const [editData, setEditData] = useState({
    name,
    description
  });

  const handleResetEdit = (name, description) => {
    setEditData({
      name,
      description
    });
  };

  return (
    <div className="flex items-center flex-col">
      <button
        id="dropdownAvatarNameButton"
        data-dropdown-toggle="dropdown-user"
        className="flex items-end bg-ink-10 p-1 rounded-full hover:bg-ink-30 focus:outline focus:outline-ink-50 focus:outline-[3px] active:bg-ink-40 text-ink-100"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
      >
        <span className="sr-only">Open Menus</span>
        <EllipsisVerticalIcon className="h-5 w-5" />
      </button>
      {isOpen && (
        <div
          className="absolute top-[1.25rem] mr-28 flex flex-col items-start z-50 my-4 text-base list-none bg-white rounded-lg shadow-1"
          id="dropdown-user"
        >
          <Modal
            variant="danger"
            title="Perbaharui"
            btnTitle="Perbaharui"
            btmRightBtnTitle="Perbaharui"
            btmLeftBtnTitle="Batal"
            btmLeftBtnFunc={() => handleResetEdit(name, description)}
            btnCloseFunc={() => handleResetEdit(name, description)}
            btmRightBtnFunc={() =>
              handleEdit(id, editData.name, editData.description)
            }
            xIcon
            btnCustomize
            btnCustomizeIcon={<PencilSquareIcon className="h-4 w-4" />}
            modalSize="md"
          >
            <div className="flex flex-col space-y-3">
              <Input
                id="name"
                label="Case Management"
                value={editData.name}
                func={(e) =>
                  setEditData((prev) => ({
                    ...prev,
                    name: e.target.value
                  }))
                }
              />
              <Input
                id="description"
                label="Deskripsi"
                value={editData.description}
                func={(e) =>
                  setEditData((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
              />
            </div>
          </Modal>
          <div className="w-full px-2">
            <div className="border-ink-40 border-[0.25px] w-full" />
          </div>
          <Modal
            variant="danger"
            title="Hapus Case Management"
            btnTitle="Hapus"
            btmRightBtnTitle="Hapus"
            btmLeftBtnTitle="Batal"
            btnCustomizeIcon={<TrashIcon className="h-4 w-4" />}
            btmRightBtnFunc={() => handleDelete(id, name)}
            xIcon
            btnCustomize
          >
            {`Anda yakin ingin menghapus '${name}' ${
              created_by.username !== '' ? `- ${created_by.username}` : ''
            } ini?`}
          </Modal>
        </div>
      )}
      {isOpen ? (
        <div
          className="fixed overflow-x-hidden overflow-y-auto inset-0"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

const filterByValue = (allData, search) => {
  return allData.filter((o) => {
    return Object.keys(o).some((k) => {
      if (typeof o[k] === 'string')
        return o[k].toLowerCase().includes(search.toLowerCase());
    });
  });
};

const UpperContainer = ({
  searchValue,
  tableName,
  handleSearch,
  leftUpper,
  rightUpper,
  searchable,
  isFetch
}) => {
  const leftActive = leftUpper || tableName;
  const rightActive = rightUpper || searchable;
  const upperPosition = leftActive ? 'justify-between' : 'justify-end';

  const nameContainer =
    tableName && (isFetch ? null : <div className="text-h-s">{tableName}</div>);

  const searchContainer = searchable && (
    <form className="flex items-center w-full md:w-auto">
      <Input
        value={searchValue}
        func={handleSearch}
        xFunc={handleSearch}
        placeholder="Cari"
        searchIcon
        xIcon
      />
    </form>
  );

  const leftSide = leftActive && (
    <div className="flex items-center gap-3 w-full md:w-1/2">
      {nameContainer}
      {leftUpper}
    </div>
  );

  const rightSide = rightActive && (
    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3">
      <div className="flex items-center gap-3">
        {rightUpper}
        {searchContainer}
      </div>
    </div>
  );

  const container = (
    <div
      className={`flex flex-col md:flex-row items-center ${upperPosition} space-y-2 md:space-y-0`}
    >
      {leftSide}
      {rightSide}
    </div>
  );

  return container;
};

const CardContainer = ({
  data,
  isFetch,
  handleClicked,
  handleEdit,
  handleDelete
}) => {
  const Card = ({disabled = false, handleClicked = () => {}, data}) => {
    const {id, name, description, created_by, is_default} = data;
    return (
      <div className="relative h-full min-h-[155px]">
        <button
          id="outer"
          className="group block w-full text-left bg-primary-surface text-l text-ink-100 border border-primary-border rounded-lg shadow-1 
          hover:bg-primary-border h-full
          focus:outline focus:outline-primary-border focus:outline-[3px] focus:shadow-0
          active:bg-primary-main active:text-ink-10
          disabled:bg-ink-30 disabled:border-ink-40 disabled:text-ink-60"
          disabled={disabled}
          onClick={() => handleClicked(id)}
        >
          <div className="w-full flex flex-col gap-3 h-full">
            <div className="w-full flex items-center gap-3 px-3 pt-3">
              <span>
                <FolderIcon className="h-5 w-5" />
              </span>
              <div className="truncate grow mr-8">{name}</div>
            </div>
            <hr className="border-1 border-primary-border group-hover:border-ink-10" />
            <div className="w-full flex flex-col gap-3 text-m px-3 pb-3 h-full">
              <div className="flex flex-col grow">
                <div className="text-s">Deskripsi:</div>
                <div className="truncate">{description || '-'}</div>
              </div>
              {is_default ? null : (
                <div className="flex flex-col">
                  <div className="text-s">Dibuat oleh:</div>
                  <div className="truncate">{created_by.username || '-'}</div>
                </div>
              )}
            </div>
          </div>
        </button>
        {is_default ? null : (
          <div className="absolute top-[10px] right-[13px]">
            <DropdownMenu
              data={data}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div>
    );
  };

  const DataNotFound = ({isFetch}) => {
    return isFetch ? (
      <Spinner
        className="fill-primary-main dark:text-ink-40"
        aria-label="Loading"
        size="lg"
      />
    ) : (
      <div className="flex w-full justify-center text-l">Tidak ada data</div>
    );
  };

  const DataWasFound = ({foundedData, handleClicked, handleDelete}) => {
    return (
      <div className="w-full grid grid-cols-4 gap-6">
        {foundedData.map((res) => {
          return (
            <Card
              key={res.id}
              data={res}
              handleClicked={handleClicked}
              handleDelete={handleDelete}
            />
          );
        })}
      </div>
    );
  };

  const dataChecking =
    data && data.length > 0 ? (
      <DataWasFound foundedData={data} handleClicked={handleClicked} />
    ) : (
      <div className="w-full bg-ink-10 p-6 text-center">
        <DataNotFound isFetch={isFetch} />
      </div>
    );

  return dataChecking;
};

const BottomContainer = ({
  page,
  handlePagination,
  handleShowEntries,
  isFetch
}) => {
  const ShowEntries = () => {
    const container = isFetch ? null : (
      <span className="text-sm font-normal text-gray-500 flex items-center">
        Menampilkan
        <Dropdown
          id="dropdownEntries"
          value={page.size}
          options={paginationSizeOptions}
          func={handleShowEntries}
          isSelection
          otherClassName={'mx-2'}
        />
        entri
      </span>
    );

    return container;
  };

  const Pagination = ({page}) => {
    const noPrevious = page.current === 1;
    const noNext = page.current === (page.total === 0 ? 1 : page.total);

    const btnClasses =
      'block px-3 py-2 ml-0 leading-tight text-ink-70 bg-white border border-ink-50 hover:bg-ink-20 hover:text-ink-80 disabled:cursor-not-allowed disabled:text-ink-60 disabled:bg-ink-30';

    const paging = (current, total) => {
      const center = [
          current - 2,
          current - 1,
          current,
          current + 1,
          current + 2
        ],
        filteredCenter = center.filter((p) => p > 1 && p < total),
        includeThreeLeft = current === 5,
        includeThreeRight = current === total - 4,
        includeLeftDots = current > 5,
        includeRightDots = current < total - 4;

      if (includeThreeLeft) filteredCenter.unshift(2);
      if (includeThreeRight) filteredCenter.push(total - 1);

      if (includeLeftDots) filteredCenter.unshift('...');
      if (includeRightDots) filteredCenter.push('...');

      const pageStatus = total <= 1 ? [1] : [1, ...filteredCenter, total];

      return pageStatus.map((res) => {
        return (
          <li key={res}>
            {res === current ? (
              <button
                onClick={() => handlePagination(res)}
                aria-current="page"
                className="z-10 px-3 py-2 leading-tight text-ink-10 border border-primary-main bg-primary-main hover:border-ink-50 hover:bg-primary-surface hover:text-primary-hover  focus:border-primary-main"
              >
                {res}
              </button>
            ) : (
              <button
                onClick={() => handlePagination(res)}
                className={btnClasses}
              >
                {res}
              </button>
            )}
          </li>
        );
      });
    };

    const container = isFetch ? null : (
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button
            disabled={noPrevious}
            type="button"
            onClick={() => handlePagination(page.current - 1)}
            className={`${btnClasses} rounded-l-lg`}
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </li>
        {paging(page.current, page.total)}
        <li>
          <button
            disabled={noNext}
            type="button"
            onClick={() => handlePagination(page.current + 1)}
            className={`${btnClasses} rounded-r-lg`}
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </li>
      </ul>
    );

    return container;
  };

  const container = (
    <nav
      className="flex flex-col justify-between md:flex-row md:items-center space-y-2 md:space-y-0"
      aria-label="Table navigation"
    >
      <ShowEntries />
      {page && <Pagination page={page} />}
    </nav>
  );

  return container;
};

const CardTable = ({
  data = [],
  page,
  tableName,
  searchable,
  leftUpper,
  rightUpper,
  handlePagination,
  handleShowEntries,
  handleSearchQuery,
  handleClicked,
  handleEdit,
  handleDelete,
  searchQuery,
  isFetch
}) => {
  const [searchData, setSearchData] = useState(data);
  const [searchValue, setSearchValue] = useState('');
  const [pageData, setPageData] = useState();
  const upperActive = leftUpper || rightUpper || searchable || tableName;

  useEffect(() => {
    setSearchData(data);
  }, [data]);

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    page &&
      setPageData({
        current: page.current,
        total: page.total,
        size: page.size,
        count: page.count
      });
  }, [page]);

  const handleSearch = (event) => {
    let filterData;
    const value = event.target.value || '';
    setSearchValue(value);
    filterData = handleSearchQuery
      ? handleSearchQuery(value)
      : filterByValue(data, value);
    setSearchData(filterData);
  };

  const upperSide = upperActive && (
    <UpperContainer
      searchValue={searchValue}
      handleSearch={handleSearch}
      searchable={searchable}
      tableName={tableName}
      leftUpper={leftUpper}
      rightUpper={rightUpper}
      isFetch={isFetch}
    />
  );

  const tableSide = (
    <CardContainer
      data={searchData}
      isFetch={isFetch}
      handleClicked={handleClicked}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );

  const bottomSide = (
    <BottomContainer
      page={pageData}
      handlePagination={handlePagination}
      handleShowEntries={handleShowEntries}
      isFetch={isFetch}
    />
  );

  const container = (
    <div className="relative space-y-6">
      {upperSide}
      {tableSide}
      {pageData && pageData.count > 0 && bottomSide}
    </div>
  );

  return container;
};

export default CardTable;
