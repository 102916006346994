const types = {
  badge: 'text-s p-1',
  tag: 'text-xs p-0.5',
  title: 'text-l p-1.5'
};

const variants = {
  success: 'bg-success-surface text-success-pressed border-success-border',
  secondary:
    'bg-secondary-surface text-secondary-pressed border-secondary-main',
  info: 'bg-info-surface text-info-pressed border-info-main',
  danger: 'bg-primary-surface text-primary-pressed border-primary-main'
};

const Badge = ({
  variant = 'primary',
  type = 'badge',
  children,
  otherClassName
}) => {
  let variantClasses = variants[variant];
  let typeClasses = types[type];

  const container = (
    <div
      className={`border font-bold rounded-lg w-fit ${variantClasses} ${typeClasses} ${otherClassName} whitespace-nowrap`}
    >
      {children}
    </div>
  );

  return container;
};

export default Badge;
