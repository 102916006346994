import {Player} from 'video-react';

const VideoModule = () => {
  const module = [
    {
      title: 'Modul Pembelajaran Propaganda Media Sosial',
      src: './assets/video/modul-1.mp4'
    },
    {
      title: 'Modul Pembelajaran Identifikasi Propaganda di Media Sosial',
      src: './assets/video/modul-2.mp4'
    },
    {
      title: 'Modul Pembelajaran Analisa Propaganda di Media Sosial',
      src: './assets/video/modul-3.mp4'
    },
    {
      title:
        'Modul Pembelajaran Pengenalan Cyber Media (Online News dan Media Sosial)',
      src: './assets/video/modul-4.mp4'
    },
    {
      title: 'Modul Pembelajaran Konsep Digital Media Analysis',
      src: './assets/video/modul-5.mp4'
    },
    {
      title: 'Modul Pembelajaran Tahapan Analisis Media Sosial',
      src: './assets/video/modul-6.mp4'
    },
    {
      title: 'Modul Pembelajaran Modelling Analysis: Media Sosial',
      src: './assets/video/modul-7.mp4'
    },
    {
      title: 'Modul Pembelajaran Social Network Analytics (SNA)',
      src: './assets/video/modul-8.mp4'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="text-h-l">Video Modul</div>
      <div className="w-full bg-ink-10 rounded-lg shadow-2 p-6 space-y-10">
        {module.length > 0 &&
          module.map((res, i) => {
            return (
              <div className="flex flex-col space-y-5" key={i}>
                <div className="text-h-s font-bold">
                  {i + 1}. {res.title}
                </div>
                <Player>
                  <source
                    src={
                      res.src === ''
                        ? './assets/video/indonesia-raya.mp4'
                        : res.src
                    }
                  />
                </Player>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default VideoModule;
