import axios from 'utils/axios';

export const getAllProxy = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get('/settings/proxy', {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const createNewProxy = (res) => {
  const {server, username, password} = res;
  return new Promise((resolve, reject) =>
    axios
      .post('/settings/proxy', {
        server,
        username,
        password
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getProxyById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/settings/proxy/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const updateProxyById = (res) => {
  const {id, server, username, password} = res;
  return new Promise((resolve, reject) =>
    axios
      .put(`/settings/proxy/${id}`, {
        server,
        username,
        password
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const deleteProxyById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .delete(`/settings/proxy/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
