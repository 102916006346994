const sizes = {
  xs: 'w-6 h-6',
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-20 h-20',
  xl: 'w-36 h-36'
};

const fontSizes = {
  xs: 'text-s',
  sm: 'text-m',
  md: 'text-l',
  lg: 'text-h-m',
  xl: 'text-h-l'
};

const Avatar = ({
  img,
  name,
  replace,
  size = 'md',
  alt,
  rounded = true,
  otherClassName
}) => {
  let initName =
    name &&
    name
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  let sizeClasses = sizes[size];
  let fontSizeClasses = fontSizes[size];
  let roundedClasses = rounded ? 'rounded-full' : 'rounded-lg';

  return img ? (
    <img
      className={`${sizeClasses} ${roundedClasses} ${otherClassName}`}
      src={img}
      alt={alt || name}
      onError={(error) => (error.target.src = replace)}
    />
  ) : (
    <div
      className={`relative inline-flex items-center justify-center ${sizeClasses} overflow-hidden bg-ink-30 ${roundedClasses} ${otherClassName}`}
    >
      <span className={`text-ink-90 font-bold ${fontSizeClasses}`}>
        {initName}
      </span>
    </div>
  );
};

export default Avatar;
