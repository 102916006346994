import {useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';

const GraphMapClustering = ({mapData}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const createCustomIcon = (cluster, index) => {
    let size = cluster / 20 > 31 ? cluster / 20 : 30;
    size = size < 76 ? size : 75;
    const isSelected = index === selectedIndex;
    return L.divIcon({
      html: `<span>${cluster}</span>`,
      className: `${
        isSelected
          ? 'bg-primary-main bg-opacity-30'
          : 'bg-secondary-main bg-opacity-40'
      } flex items-center justify-center rounded-full  text-black border-2 border-primary-border`,
      iconSize: L.point(size, size, true)
    });
  };

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <MapContainer
      className="h-[60vh] sticky"
      center={[-2, 120]}
      zoom={5}
      maxZoom={8}
      minZoom={4}
      scrollWheelZoom={false}
      placeholder={
        <noscript>You need to enable JavaScript to see this map.</noscript>
      }
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {mapData.length > 0 &&
        mapData.map(
          (res, index) =>
            res.news_count > 0 && (
              <Marker
                position={[res.latitude, res.longitude]}
                key={index}
                title={res.name}
                icon={createCustomIcon(res.news_count, index)}
                eventHandlers={{
                  click: () => {
                    handleClick(index);
                  }
                }}
              >
                <Popup>
                  <div className="space-y-2">
                    <div className="font-bold">{res.name}</div>
                    <div>{res.news_count} berita</div>
                  </div>
                </Popup>
              </Marker>
            )
        )}
    </MapContainer>
  );
};

export default GraphMapClustering;
