import {createStore, combineReducers} from 'redux';
import authReducer from 'store/reducers/auth';

const rootReducer = combineReducers({
  auth: authReducer
});

const store = createStore(rootReducer);

export default store;
