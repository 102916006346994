import axios from 'utils/axios';

export const changePassword = (res) => {
  const {old_password, new_password, password_confirmation} = res;
  return new Promise((resolve, reject) =>
    axios
      .post('/change-password', {
        old_password,
        new_password,
        password_confirmation
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
