import {useState, useEffect} from 'react';
import {Button, Input} from 'components';
import {useNavigate, useParams} from 'react-router-dom';
import * as service from 'services';
import {AlertValidation} from 'utils/validation';
import {Spinner} from 'flowbite-react';

const EditAccountInstagram = () => {
  const navigate = useNavigate();
  const {id: userId} = useParams();

  const [value, setValue] = useState({
    id: userId,
    username: '',
    password: ''
  });

  const [alert, setAlert] = useState(null);
  const [isFetch, setFetch] = useState(true);
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  const fetchUserDataById = async (id) => {
    try {
      const data = await service.Settings.Instagram.getAccountById(id);

      setValue((prev) => ({
        ...prev,
        username: data.username,
        password: data.password,
        email_address: data.email_address,
        email_password: data.email_password
      }));
      setFetch(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  useEffect(() => {
    fetchUserDataById(userId);
  }, []);

  const handleInputChange = (e) => {
    const id = (e.target && e.target.id) || e.id;
    const val = (e.target && e.target.value) || e.value || '';

    setValue((prev) => ({
      ...prev,
      [id]: val
    }));
  };

  const handleUpdateUser = async (e, data) => {
    e.preventDefault();

    setAlert(null);
    setUpdateLoading(true);

    try {
      await service.Settings.Instagram.updateAccountById(data);
      navigate(-1);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setUpdateLoading(false);
    }
  };

  const container = isFetch ? (
    <div className="text-center">
      <Spinner
        className="mr-3 fill-primary-main dark:text-ink-40"
        aria-label="Loading"
        size="lg"
      />
    </div>
  ) : (
    <form className="space-y-8" action="#">
      <div className="flex flex-col md:flex-row items-center w-full space-x-0 space-y-8 md:space-x-16 md:space-y-0">
        <div className="flex flex-col w-full space-y-8">
          <Input
            id="username"
            label="Username"
            value={value.username}
            func={handleInputChange}
          />
          <Input
            id="password"
            label="Kata Sandi"
            passIcon
            value={value.password}
            func={handleInputChange}
          />
        </div>
        <div className="flex flex-col w-full space-y-8">
          <Input
            id="email_address"
            label="Email"
            value={value.email_address}
            func={handleInputChange}
          />
          <Input
            id="email_password"
            label="Kata Sandi Email"
            passIcon
            value={value.email_password}
            func={handleInputChange}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-end">
        <div className="w-full sm:w-auto flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0">
          <Button
            otherClassName="order-last sm:order-first"
            variant="outlined"
            func={() => navigate(-1)}
          >
            Batal
          </Button>
          <Button
            type="submit"
            func={(e) => handleUpdateUser(e, value)}
            isLoading={isUpdateLoading}
          >
            Perbaharui
          </Button>
        </div>
      </div>
    </form>
  );

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Perbaharui Pengguna - Instagram</div>
      <div className="w-full bg-ink-10 p-10 md:px-20 lg:px-32 lg:py-14 xl:px-40 xl:py-16 2xl:px-48 2xl:py-20 rounded-lg shadow-2">
        {container}
      </div>
    </div>
  );
};

export default EditAccountInstagram;
