import axios from 'utils/axios';

export const getAllDashboard = () => {
  return new Promise((resolve, reject) =>
    axios
      .get('/dashboard')
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
