import {useState, useEffect} from 'react';
import {Dropdown, Input, Checkbox} from 'components';
import {paginationSizeOptions} from 'utils/paginationOptions';

const textSubTitleSkeleton = 'h-2.5';
const textSkeletonClasses = 'my-2 rounded bg-ink-50';

const filterByValue = (allData, search) => {
  return allData.filter((o) => {
    return Object.keys(o).some((k) => {
      if (typeof o[k] === 'string')
        return o[k].toLowerCase().includes(search.toLowerCase());
    });
  });
};

const UpperContainer = ({
  searchValue,
  tableName,
  handleSearch,
  leftUpper,
  rightUpper,
  searchable,
  isFetch
}) => {
  const leftActive = leftUpper || tableName;
  const rightActive = rightUpper || searchable;
  const upperPosition = leftActive ? 'justify-between' : 'justify-end';

  const nameContainer =
    tableName &&
    (isFetch ? (
      <div
        className={`${textSubTitleSkeleton} ${textSkeletonClasses} w-[200px]`}
      />
    ) : (
      <div className="text-h-s">{tableName}</div>
    ));

  const searchContainer = searchable && (
    <form className="flex items-center w-full md:w-auto">
      <Input
        value={searchValue}
        func={handleSearch}
        xFunc={handleSearch}
        placeholder="Cari"
        searchIcon
        xIcon
      />
    </form>
  );

  const leftSide = leftActive && (
    <div className="flex items-center gap-3 w-full md:w-1/2">
      {nameContainer}
      {leftUpper}
    </div>
  );

  const rightSide = rightActive && (
    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3">
      <div className="flex items-center gap-3">
        {rightUpper}
        {searchContainer}
      </div>
    </div>
  );

  const container = (
    <div
      className={`flex flex-col md:flex-row items-center ${upperPosition} space-y-2 md:space-y-0`}
    >
      {leftSide}
      {rightSide}
    </div>
  );

  return container;
};

const TableContainer = ({columns, data, isFetch}) => {
  const HeaderContainer = ({col}) => {
    const checkBoxType = (
      <th scope="col" className="px-6 py-4" key={col.dataField}>
        <div className="flex items-center">
          <Checkbox id="checkbox-2" checked={col.is_checked} func={col.func} />
        </div>
      </th>
    );
    const defaultType = isFetch ? (
      <th
        scope="col"
        className={`px-6 py-4 text-${col.headerAlign || 'left'}`}
        key={col.dataField}
      >
        <div
          className={`${textSubTitleSkeleton} ${textSkeletonClasses} w-1/2
          ${col.headerAlign === 'center' && 'm-auto'}`}
        />
      </th>
    ) : (
      <th
        scope="col"
        className={`px-6 py-4 text-${col.headerAlign || 'left'}`}
        key={col.dataField}
      >
        {col && col.text}
      </th>
    );

    const selectType = <div></div>;

    const types = {
      select: selectType,
      checkbox: checkBoxType,
      default: defaultType
    };

    const type = types[col.type || 'default'];

    return type;
  };

  const BodyContainer = ({columns, data}) => {
    const rowClasses = 'bg-white border-b hover:bg-gray-50';
    const columnClasses = 'px-6 py-4';

    const DataFetching = ({columnsData}) => {
      return (
        <tr>
          {columnsData.map((col) => {
            return (
              <td
                className={`${columnClasses} text-${
                  col.columnAlign || col.headerAlign || 'left'
                }`}
                key={col.dataField}
              >
                <div
                  className={`${textSubTitleSkeleton} ${textSkeletonClasses} w-1/2
                  ${
                    (col.columnAlign === 'center' ||
                      col.headerAlign === 'center') &&
                    'm-auto'
                  }`}
                />
              </td>
            );
          })}
        </tr>
      );
    };

    const DataNotFound = ({columnsLength}) => {
      return (
        <tr className={rowClasses}>
          <td
            className={`${columnClasses} text-center`}
            colSpan={columnsLength}
          >
            Tidak ada data
          </td>
        </tr>
      );
    };

    const DataWasFound = ({foundedData, columnsData}) => {
      return foundedData.map((res) => {
        return (
          <tr className={rowClasses} key={res.id}>
            {columnsData.map((col) => {
              return (
                <td
                  className={`${columnClasses} text-${
                    col.columnAlign || col.headerAlign || 'left'
                  }`}
                  key={col.dataField}
                >
                  {(col.formatter &&
                    col.formatter(res[col.dataField] || res, res)) ||
                    res[col.dataField]}
                </td>
              );
            })}
          </tr>
        );
      });
    };

    const dataChecking =
      data && data.length > 0 ? (
        <DataWasFound foundedData={data} columnsData={columns} />
      ) : (
        <DataNotFound columnsLength={columns.length} />
      );

    const container = isFetch ? (
      <>
        <DataFetching columnsData={columns} />
        <DataFetching columnsData={columns} />
        <DataFetching columnsData={columns} />
      </>
    ) : (
      dataChecking
    );

    return container;
  };

  const headerSide = (
    <thead
      className={`text-bold text-ink-100 ${
        isFetch ? 'bg-ink-30' : 'bg-ink-20'
      }`}
    >
      <tr>
        {columns.map((col) => (
          <HeaderContainer col={col} key={col.dataField} />
        ))}
      </tr>
    </thead>
  );

  const bodySide = (
    <tbody>
      <BodyContainer columns={columns} data={data} />
    </tbody>
  );

  const container = (
    <table className="w-full text-m text-regular text-left text-ink-90">
      {headerSide}
      {bodySide}
    </table>
  );

  return container;
};

const BottomContainer = ({
  page,
  handlePagination,
  handleShowEntries,
  isFetch
}) => {
  const ShowEntries = () => {
    // const startNumber =
    //   page.count === 0 ? 0 : (page.current - 1) * page.size + 1;
    // const endNumber = page.current * page.size;
    // const number =
    //   page.count > endNumber ? `${startNumber}-${endNumber}` : page.count;

    const container = isFetch ? (
      <span
        className={`${textSubTitleSkeleton} ${textSkeletonClasses} w-[120px]`}
      />
    ) : (
      <span className="text-sm font-normal text-gray-500 flex items-center">
        Menampilkan
        <Dropdown
          id="dropdownEntries"
          value={page.size}
          options={paginationSizeOptions}
          func={handleShowEntries}
          isSelection
          otherClassName={'mx-2'}
        />
        entri
        {/* <span className="font-semibold text-gray-900">
          {page.count}
        </span> */}
      </span>
    );

    return container;
  };

  const Pagination = ({page}) => {
    const noPrevious = page.current === 1;
    const noNext = page.current === (page.total === 0 ? 1 : page.total);

    const btnClasses =
      'block px-3 py-2 ml-0 leading-tight text-ink-70 bg-white border border-ink-50 hover:bg-ink-20 hover:text-ink-80 disabled:cursor-not-allowed disabled:text-ink-60 disabled:bg-ink-30';

    const paging = (current, total) => {
      const center = [
          current - 2,
          current - 1,
          current,
          current + 1,
          current + 2
        ],
        filteredCenter = center.filter((p) => p > 1 && p < total),
        includeThreeLeft = current === 5,
        includeThreeRight = current === total - 4,
        includeLeftDots = current > 5,
        includeRightDots = current < total - 4;

      if (includeThreeLeft) filteredCenter.unshift(2);
      if (includeThreeRight) filteredCenter.push(total - 1);

      if (includeLeftDots) filteredCenter.unshift('...');
      if (includeRightDots) filteredCenter.push('...');

      const pageStatus = total <= 1 ? [1] : [1, ...filteredCenter, total];

      return pageStatus.map((res) => {
        return (
          <li key={res}>
            {res === current ? (
              <button
                onClick={() => handlePagination(res)}
                aria-current="page"
                className="z-10 px-3 py-2 leading-tight text-ink-10 border border-primary-main bg-primary-main hover:border-ink-50 hover:bg-primary-surface hover:text-primary-hover"
              >
                {res}
              </button>
            ) : (
              <button
                onClick={() => handlePagination(res)}
                className={btnClasses}
              >
                {res}
              </button>
            )}
          </li>
        );
      });
    };

    const container = isFetch ? (
      <span className={`h-4 ${textSkeletonClasses} w-[150px]`} />
    ) : (
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button
            disabled={noPrevious}
            type="button"
            onClick={() => handlePagination(page.current - 1)}
            className={`${btnClasses} rounded-l-lg`}
          >
            <span className="sr-only">Previous</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </li>
        {paging(page.current, page.total)}
        <li>
          <button
            disabled={noNext}
            type="button"
            onClick={() => handlePagination(page.current + 1)}
            className={`${btnClasses} rounded-r-lg`}
          >
            <span className="sr-only">Next</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </li>
      </ul>
    );

    return container;
  };

  const container = (
    <nav
      className="flex flex-col justify-between md:flex-row md:items-center space-y-2 md:space-y-0"
      aria-label="Table navigation"
    >
      <ShowEntries />
      {page && <Pagination page={page} />}
    </nav>
  );

  return container;
};

const Table = ({
  columns = [],
  data = [],
  page,
  tableName,
  searchable,
  leftUpper,
  rightUpper,
  handlePagination,
  handleShowEntries,
  handleSearchQuery,
  searchQuery,
  isFetch
}) => {
  const [searchData, setSearchData] = useState(data);
  const [searchValue, setSearchValue] = useState('');
  const [pageData, setPageData] = useState();
  const upperActive = leftUpper || rightUpper || searchable || tableName;

  useEffect(() => {
    setSearchData(data);
  }, [data]);

  useEffect(() => {
    setSearchValue(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    page &&
      setPageData({
        current: page.current,
        total: page.total,
        size: page.size,
        count: page.count
      });
  }, [page]);

  const handleSearch = (event) => {
    let filterData;
    const value = event.target.value || '';
    setSearchValue(value);
    filterData = handleSearchQuery
      ? handleSearchQuery(value)
      : filterByValue(data, value);
    setSearchData(filterData);
  };

  const upperSide = upperActive && (
    <UpperContainer
      searchValue={searchValue}
      handleSearch={handleSearch}
      searchable={searchable}
      tableName={tableName}
      leftUpper={leftUpper}
      rightUpper={rightUpper}
      isFetch={isFetch}
    />
  );

  const tableSide = (
    <div className="overflow-x-auto shadow-2 rounded-lg">
      <TableContainer columns={columns} data={searchData} isFetch={isFetch} />
    </div>
  );

  const bottomSide = (
    <BottomContainer
      page={pageData}
      handlePagination={handlePagination}
      handleShowEntries={handleShowEntries}
      isFetch={isFetch}
    />
  );

  const container = (
    <div className="relative space-y-6">
      {upperSide}
      {tableSide}
      {pageData && pageData.count > 0 && bottomSide}
    </div>
  );

  return container;
};

export default Table;
