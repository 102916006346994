const CardButton = ({disabled = false, children, func = () => {}}) => {
  return (
    <button
      type="button"
      className="block w-full p-6 bg-ink-10 text-h-s text-ink-100 border border-primary-border rounded-lg shadow-1 
      hover:bg-primary-surface
      focus:outline focus:outline-primary-border focus:outline-[3px] focus:bg-primary-surface focus:shadow-0
      active:bg-primary-main active:text-ink-10
      disabled:bg-ink-30 disabled:border-ink-40 disabled:text-ink-60"
      disabled={disabled}
      onClick={func}
    >
      {children}
    </button>
  );
};

export default CardButton;
