import {Main, Login} from 'modules/index';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import {BrowserRouter, HashRouter, Routes, Route} from 'react-router-dom';

const App = () => {
  const status =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<PrivateRoute Component={Main} />} />
          <Route path="/login" element={<PublicRoute Component={Login} />} />
        </Routes>
      </BrowserRouter>
    ) : (
      <HashRouter>
        <Routes>
          <Route path="*" element={<PrivateRoute Component={Main} />} />
          <Route path="/login" element={<PublicRoute Component={Login} />} />
        </Routes>
      </HashRouter>
    );
  return status;
};

export default App;
