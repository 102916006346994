import {Alert} from 'components/index';

export const validationStatus = (err) => {
  const error =
    (err &&
      err.response &&
      err.response.data &&
      err.response.data.validation_errors &&
      err.response.data.validation_errors[0] &&
      err.response.data.validation_errors[0].message &&
      err.response.data.validation_errors[0].message[0]) ||
    (err && err.response && err.response.data && err.response.data.message) ||
    (err && err.response && err.response.statusText) ||
    (err && err.message) ||
    err ||
    'Error';

  return error;
};

export const AlertValidation = (err, variant = 'danger') => {
  const alert = <Alert variant={variant}>{validationStatus(err)}</Alert>;

  return alert;
};
