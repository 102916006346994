export const optionsSocialMedia = [
  {
    value: 'twitter',
    label: 'Twitter'
  },
  {
    value: 'facebook',
    label: 'Facebook'
  },
  {
    value: 'tiktok',
    label: 'TikTok'
  },
  {
    value: 'instagram',
    label: 'Instagram'
  }
];

export const optionsFixNews = [
  {
    value: 'news:cnn',
    label: 'CNN Indonesia'
  },
  {
    value: 'news:detik',
    label: 'detikNews'
  },
  {
    value: 'news:idntimes',
    label: 'IDN Times'
  },
  {
    value: 'news:inews',
    label: 'iNews.id'
  },
  {
    value: 'news:jpnn',
    label: 'JPNN.com'
  },
  {
    value: 'news:kompas',
    label: 'Kompas.com'
  },
  {
    value: 'news:liputan6',
    label: 'Liputan6.com'
  },
  {
    value: 'news:merdeka',
    label: 'Merdeka.com'
  },
  {
    value: 'news:okezone',
    label: 'Okezone News'
  },
  {
    value: 'news:republika',
    label: 'Republika Online'
  },
  {
    value: 'news:sindo',
    label: 'SINDOnews'
  },
  {
    value: 'news:suara',
    label: 'Suara.com'
  },
  {
    value: 'news:tempo',
    label: 'TEMPO.co'
  },
  {
    value: 'news:tribun',
    label: 'TribunNews.com'
  },
  {
    value: 'news:tvone',
    label: 'tvOneNews.com'
  }
];

export const optionsOthersNews = [
  {
    value: 'news:antara',
    label: 'ANTARA News'
  },
  {
    value: 'news:beritasatu',
    label: 'BeritaSatu.com'
  },
  {
    value: 'news:bisnis',
    label: 'Bisnis.com'
  },
  {
    value: 'news:cnbc',
    label: 'CNBC Indonesia'
  },
  {
    value: 'news:detik_bali',
    label: 'detikBali'
  },
  {
    value: 'news:detik_finance',
    label: 'detikFinance'
  },
  {
    value: 'news:detik_food',
    label: 'detikFood'
  },
  {
    value: 'news:detik_health',
    label: 'detikHealth'
  },
  {
    value: 'news:detik_hikmah',
    label: 'detikHikmah'
  },
  {
    value: 'news:detik_hot',
    label: 'detikHot'
  },
  {
    value: 'news:detik_inet',
    label: 'detikInet'
  },
  {
    value: 'news:detik_jabar',
    label: 'detikJabar'
  },
  {
    value: 'news:detik_jateng',
    label: 'detikJateng'
  },
  {
    value: 'news:detik_jatim',
    label: 'detikJatim'
  },
  {
    value: 'news:detik_jogja',
    label: 'detikJogja'
  },
  {
    value: 'news:detik_oto',
    label: 'detikOtomotif'
  },
  {
    value: 'news:detik_properti',
    label: 'detikProperti'
  },
  {
    value: 'news:detik_sport',
    label: 'detikSport'
  },
  {
    value: 'news:detik_sulsel',
    label: 'detikSulsel'
  },
  {
    value: 'news:detik_sumbagsel',
    label: 'detikSumbagsel'
  },
  {
    value: 'news:detik_sumut',
    label: 'detikSumut'
  },
  {
    value: 'news:detik_travel',
    label: 'detikTravel'
  },
  {
    value: 'news:detik_wolipop',
    label: 'Wolipop'
  },
  {
    value: 'news:kompas_edukasi',
    label: 'Kompas Edukasi'
  },
  {
    value: 'news:kompas_health',
    label: 'Kompas Health'
  },
  {
    value: 'news:kompas_homey',
    label: 'Kompas Homey'
  },
  {
    value: 'news:kompas_money',
    label: 'Kompas Money'
  },
  {
    value: 'news:kompas_properti',
    label: 'Kompas Properti'
  },
  {
    value: 'news:kompas_sains',
    label: 'Kompas Sains'
  },
  {
    value: 'news:kompas_tekno',
    label: 'Kompas Tekno'
  },
  {
    value: 'news:kompas_travel',
    label: 'Kompas Travel'
  },
  {
    value: 'news:kompas_tren',
    label: 'Kompas Tren'
  },
  {
    value: 'news:kontan',
    label: 'KONTAN'
  },
  {
    value: 'news:liputan6_bisnis',
    label: 'Liputan6 Bisnis'
  },
  {
    value: 'news:liputan6_bola',
    label: 'Liputan6 Bola'
  },
  {
    value: 'news:liputan6_cekfakta',
    label: 'Liputan6 Cek Fakta'
  },
  {
    value: 'news:liputan6_citizen6',
    label: 'Liputan6 Citizen6'
  },
  {
    value: 'news:liputan6_crypto',
    label: 'Liputan6 Crypto'
  },
  {
    value: 'news:liputan6_disabilitas',
    label: 'Liputan6 Disabilitas'
  },
  {
    value: 'news:liputan6_global',
    label: 'Liputan6 Global'
  },
  {
    value: 'news:liputan6_health',
    label: 'Liputan6 Health'
  },
  {
    value: 'news:liputan6_hot',
    label: 'Liputan6 Hot'
  },
  {
    value: 'news:liputan6_islami',
    label: 'Liputan6 Islami'
  },
  {
    value: 'news:liputan6_lifestyle',
    label: 'Liputan6 Lifestyle'
  },
  {
    value: 'news:liputan6_otomotif',
    label: 'Liputan6 Otomotif'
  },
  {
    value: 'news:liputan6_pemilu',
    label: 'Liputan6 Pemilu'
  },
  {
    value: 'news:liputan6_regional',
    label: 'Liputan6 Regional'
  },
  {
    value: 'news:liputan6_saham',
    label: 'Liputan6 Saham'
  },
  {
    value: 'news:liputan6_showbiz',
    label: 'Liputan6 Showbiz'
  },
  {
    value: 'news:liputan6_surabaya',
    label: 'Liputan6 Surabaya'
  },
  {
    value: 'news:liputan6_tekno',
    label: 'Liputan6 Tekno'
  },
  {
    value: 'news:metro',
    label: 'MetroTV'
  },
  {
    value: 'news:rmol',
    label: 'RMOL.ID'
  },
  {
    value: 'news:rmol_aceh',
    label: 'RMOL Aceh'
  },
  {
    value: 'news:rmol_banten',
    label: 'RMOL Banten'
  },
  {
    value: 'news:rmol_bengkulu',
    label: 'RMOL Bengkulu'
  },
  {
    value: 'news:rmol_dkijakarta',
    label: 'RMOL DKI Jakarta'
  },
  {
    value: 'news:rmol_jabar',
    label: 'RMOL Jawa Barat'
  },
  {
    value: 'news:rmol_jateng',
    label: 'RMOL Jawa Tengah'
  },
  {
    value: 'news:rmol_jatim',
    label: 'RMOL Jawa Timur'
  },
  {
    value: 'news:rmol_lampung',
    label: 'RMOL Lampung'
  },
  {
    value: 'news:rmol_sumbar',
    label: 'RMOL Sumatera Barat'
  },
  {
    value: 'news:rmol_sumsel',
    label: 'RMOL Sumatera Selatan'
  },
  {
    value: 'news:rmol_sumut',
    label: 'RMOL Sumatera Utara'
  },
  {
    value: 'news:tribun_jakarta',
    label: 'Tribun Jakarta'
  },
  {
    value: 'news:tribun_jateng',
    label: 'Tribun Jateng'
  },
  {
    value: 'news:tribun_jatim',
    label: 'Tribun Jatim'
  },
  {
    value: 'news:tribun_kalteng',
    label: 'Tribun Kalteng'
  },
  {
    value: 'news:tribun_kaltim',
    label: 'Tribun Kaltim'
  },
  {
    value: 'news:tribun_manado',
    label: 'Tribun Manado'
  },
  {
    value: 'news:tribun_pekanbaru',
    label: 'Tribun Pekanbaru'
  },
  {
    value: 'news:tribun_sulbar',
    label: 'Tribun Sulbar'
  },
  {
    value: 'news:tribun_sultra',
    label: 'Tribun Sultra'
  },
  {
    value: 'news:tribun_sumsel',
    label: 'Tribun Sumsel'
  },
  {
    value: 'news:wartaekonomi',
    label: 'Warta Ekonomi'
  }
];

const otherNews = [
  {
    value: 'other_news',
    label: 'Berita Lainnya'
  }
];

export const options = [
  ...optionsSocialMedia,
  ...optionsFixNews,
  ...optionsOthersNews,
  ...otherNews
];

export const topicOptions = [
  {
    label: 'Sosial Media',
    options: [...optionsSocialMedia]
  },
  {
    label: 'Berita',
    options: [
      ...optionsFixNews,
      {
        value: optionsOthersNews.map(({value}) => value),
        label: 'Berita Lainnya'
      }
    ]
  }
];
