import {useState, useEffect} from 'react';
import {Button, Input} from 'components';
import {useNavigate, useParams} from 'react-router-dom';
import * as service from 'services';
import {AlertValidation} from 'utils/validation';
import {Spinner} from 'flowbite-react';

const EditTokenTiktok = () => {
  const navigate = useNavigate();
  const {id: userId} = useParams();

  const [value, setValue] = useState({
    id: userId,
    ms_token: ''
  });

  const [alert, setAlert] = useState(null);
  const [isFetch, setFetch] = useState(true);
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  const fetchTokenDataById = async (id) => {
    try {
      const data = await service.Settings.TikTok.getTokenById(id);
      setValue({
        id: data.id,
        ms_token: data.ms_token
      });

      setFetch(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  useEffect(() => {
    fetchTokenDataById(userId);
  }, []);

  const handleUpdateToken = async (data) => {
    setAlert(null);
    setUpdateLoading(true);

    try {
      await service.Settings.TikTok.updateTokenById(data);
      navigate(-1);
    } catch (err) {
      setAlert(AlertValidation(err));
    } finally {
      setUpdateLoading(false);
    }
  };

  const container = isFetch ? (
    <div className="text-center">
      <Spinner
        className="mr-3 fill-primary-main dark:text-ink-40"
        aria-label="Loading"
        size="lg"
      />
    </div>
  ) : (
    <>
      <Input
        id="ms_token"
        label="Token"
        value={value.ms_token}
        func={(e) =>
          setValue((prev) => ({
            ...prev,
            ms_token: e.target.value
          }))
        }
      />
      <div className="flex flex-col sm:flex-row items-center justify-end">
        <div className="w-full sm:w-auto flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0">
          <Button
            otherClassName="order-last sm:order-first"
            variant="outlined"
            func={() => navigate(-1)}
          >
            Batal
          </Button>
          <Button
            type="submit"
            func={() => handleUpdateToken(value, userId)}
            isLoading={isUpdateLoading}
          >
            Perbaharui
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Perbaharui Pengguna - TikTok</div>
      <div className="w-full bg-ink-10 p-10 md:px-20 lg:px-32 lg:py-14 xl:px-40 xl:py-16 2xl:px-48 2xl:py-20 rounded-lg shadow-2 space-y-8">
        {container}
      </div>
    </div>
  );
};

export default EditTokenTiktok;
