import axios from 'utils/axios';

export const loginByAuth = async (username, password) => {
  return new Promise((resolve, reject) =>
    axios
      .post('/authentication/login', {
        username,
        password
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
