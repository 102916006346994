import {useState, useEffect} from 'react';
import {BackDrop, Header, MenuSidebar, Undefined} from 'modules';
import {AlertValidation} from 'utils/validation';

import {
  ChangePassword,
  Dashboard,
  Topics,
  NewTopic,
  AnalyticResultTopic,
  CaseManagement,
  NewCaseManagement,
  VideoModule,
  UserManagement,
  NewUserManagement,
  EditUserManagement,
  Settings,
  SettingsTiktok,
  NewTokenTiktok,
  EditTokenTiktok,
  SettingsInstagram,
  NewAccountInstagram,
  EditAccountInstagram,
  SettingsFacebook,
  NewAccountFacebook,
  EditAccountFacebook,
  SettingsTwitter,
  NewAccountTwitter,
  EditAccountTwitter,
  SettingsProxy,
  NewProxy,
  EditProxy
} from 'pages';

import {Routes, Route, Outlet} from 'react-router-dom';
import {connect} from 'react-redux';

const Main = ({is_admin}) => {
  const [sidebar, setSideBar] = useState(window.innerWidth > 640);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 640;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const handleToggleSidebar = () => {
    setSideBar(!sidebar);
  };

  const routing = navigator.onLine ? (
    <Routes>
      <Route exact path="/" element={<Dashboard />} />
      <Route exact path="/case-management" element={<Outlet />}>
        <Route path="" element={<CaseManagement />} />
        <Route path="new-case-management" element={<NewCaseManagement />} />
        <Route path="keywords/:topicId/new-keyword" element={<NewTopic />} />
        <Route path="keywords/:topicId" element={<Topics />} />
        <Route
          path="keywords/:topicId/:resultId/result"
          element={<AnalyticResultTopic />}
        />
      </Route>
      <Route exact path="/video-module" element={<Outlet />}>
        <Route path="" element={<VideoModule />} />
      </Route>
      {is_admin && (
        <>
          <Route exact path="/user-management" element={<Outlet />}>
            <Route path="" element={<UserManagement />} />
            <Route path="new-user-management" element={<NewUserManagement />} />
            <Route exact path=":id/edit" element={<EditUserManagement />} />
          </Route>
          <Route exact path="/settings" element={<Outlet />}>
            <Route path="" element={<Settings />} />
            <Route path="tiktok" element={<Outlet />}>
              <Route path="" element={<SettingsTiktok />} />
              <Route path="new-token-tiktok" element={<NewTokenTiktok />} />
              <Route path=":id/edit" element={<EditTokenTiktok />} />
            </Route>
            <Route path="instagram" element={<Outlet />}>
              <Route path="" element={<SettingsInstagram />} />
              <Route
                path="new-account-instagram"
                element={<NewAccountInstagram />}
              />
              <Route path=":id/edit" element={<EditAccountInstagram />} />
            </Route>
            <Route path="facebook" element={<Outlet />}>
              <Route path="" element={<SettingsFacebook />} />
              <Route
                path="new-account-facebook"
                element={<NewAccountFacebook />}
              />
              <Route path=":id/edit" element={<EditAccountFacebook />} />
            </Route>
            <Route path="twitter" element={<Outlet />}>
              <Route path="" element={<SettingsTwitter />} />
              <Route
                path="new-account-twitter"
                element={<NewAccountTwitter />}
              />
              <Route path=":id/edit" element={<EditAccountTwitter />} />
            </Route>
            <Route path="proxy" element={<Outlet />}>
              <Route path="" element={<SettingsProxy />} />
              <Route path="new-proxy" element={<NewProxy />} />
              <Route path=":id/edit" element={<EditProxy />} />
            </Route>
          </Route>
        </>
      )}
      <Route exact path="/change-password" element={<ChangePassword />} />
      <Route path="*" element={<Undefined />} />
    </Routes>
  ) : (
    AlertValidation(
      'Periksa ulang koneksi internet. Error: Tidak ada koneksi internet yang terhubung.'
    )
  );

  const template = (
    <>
      <Header sidebar={sidebar} handleSidebar={handleToggleSidebar} />
      <MenuSidebar sidebar={sidebar} />
      <div className="content-wrapper">
        <section
          className={`content transition-all ${
            sidebar ? 'sm:ml-64' : 'sm:ml-20'
          }`}
        >
          <div className="p-4">
            <div className="py-4 px-2 text-ink-90 mt-14">{routing}</div>
          </div>
        </section>
      </div>
      {isMobile && sidebar && (
        <BackDrop handleClose={() => setSideBar(false)} />
      )}
    </>
  );

  return template;
};

const mapStateToProps = (state) => ({
  is_admin: state.auth.isAdmin
});

export default connect(mapStateToProps, null)(Main);
