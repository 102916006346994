import {useEffect, useState} from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import {CalendarIcon, XMarkIcon} from '@heroicons/react/20/solid';

const DatePicker = ({id, label, value, func = () => {}, disabled = false}) => {
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  });
  const handleDateChange = (newValue) => {
    setDate(newValue);
    func({
      id: id,
      value: newValue.startDate
    });
  };

  useEffect(() => {
    setDate({
      startDate: value,
      endDate: value
    });
  }, [value]);

  return (
    <div className="space-y-3 w-full">
      {label && (
        <label htmlFor={id} className="block text-m font-bold text-ink-100">
          {label}
        </label>
      )}
      <Datepicker
        inputClassName={`
        bg-ink-10 border border-ink-50 text-m text-regular rounded-lg w-full py-2 px-2.5 pr-8
        hover:border-primary-main
        focus:border-primary-main focus:outline focus:outline-[3px] focus:outline-primary-focus focus:outline-offset-0 focus:text-ink-100
        disabled:bg-ink-30 disabled:border-ink-50
        read-only:bg-ink-30 read-only:border-ink-50 
        `}
        containerClassName={'relative text-m text-regular'}
        toggleClassName={'absolute bottom-2.5 right-2.5 text-ink-90'}
        toggleIcon={(isEmpty) => {
          return isEmpty ? (
            <CalendarIcon className="h-5 w-5" />
          ) : (
            <XMarkIcon className="h-5 w-5" />
          );
        }}
        useRange={false}
        asSingle={true}
        value={date}
        onChange={handleDateChange}
        disabled={disabled}
        displayFormat={'DD/MM/YYYY'}
      />
    </div>
  );
};
export default DatePicker;
