import {useEffect, useState} from 'react';
import {Button} from 'components';
import {XMarkIcon, KeyIcon} from '@heroicons/react/20/solid';

const variants = {
  primary: 'text-primary-main',
  secondary: 'text-primary-surface',
  outlined: 'text-ink-60',
  danger: 'text-danger-main',
  text: 'text-primary-main'
};

const modalSizes = {
  auto: 'w-auto',
  s: 'w-[200px]',
  md: 'w-[500px]',
  l: 'w-[750px]'
};

const Modal = ({
  icon,
  variant = 'primary',
  btmRightBtnVariant = variant,
  btnTitle = 'Button',
  title = 'Modal',
  show = false,
  btmLeftBtnTitle = 'Cancel',
  btmRightBtnTitle = 'Confirm',
  btmLeftBtnFunc,
  btmRightBtnFunc,
  btmBtnBetween = false,
  isBtmBtnLeft = true,
  isBtmBtnRight = true,
  btnIsShadow = true,
  size = 'md',
  btnCloseFunc,
  xIcon,
  modalSize = 'auto',
  children,
  btnCustomize,
  btnCustomizeIcon = <KeyIcon className="h-4 w-4" />
}) => {
  const [showModal, setShowModal] = useState(show);
  let variantClasses = variants[variant];
  let modalSizesClasses = modalSizes[modalSize];

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleActionBtn = (e) => {
    e.stopPropagation();
    btmRightBtnFunc && btmRightBtnFunc();
    setShowModal(false);
  };

  const handleCancelBtn = (e) => {
    e.stopPropagation();
    btmLeftBtnFunc && btmLeftBtnFunc();
    setShowModal(false);
  };

  const handleCloseFunc = (e) => {
    e.stopPropagation();
    btnCloseFunc && btnCloseFunc();
    setShowModal(false);
  };

  const Menu = ({func = () => {}, children, icon}) => {
    return (
      <div className="w-full">
        <div className="py-1.5 mx-1.5">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              func();
            }}
            className="
            flex items-center px-4 py-2 text-sm text-ink-90 rounded-lg w-full
            hover:bg-danger-surface hover:text-danger-main active:bg-danger-main active:text-ink-10"
          >
            {icon}
            <span className="flex-1 ml-3 whitespace-nowrap text-left text-m">
              {children}
            </span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {btnCustomize ? (
        <Menu icon={btnCustomizeIcon} func={() => setShowModal(true)}>
          {btnTitle}
        </Menu>
      ) : (
        <Button
          variant={variant}
          func={() => setShowModal(true)}
          isShadow={btnIsShadow}
          size={size}
        >
          {btnTitle}
        </Button>
      )}

      {showModal && (
        <>
          <div className="transition flex justify-center items-center overflow-x-hidden overflow-y-auto px-5 sm:px-0 fixed inset-0 z-50 bg-ink-90 bg-opacity-50 ml-0">
            <div
              className={`relative ${modalSizesClasses} my-6 mx-auto 2xl:max-w-3xl lg:max-w-xl sm:max-w-md`}
            >
              <div className="shadow-2 rounded-lg shadow-lg relative flex flex-col w-full bg-white">
                <div className="p-6 text-left">
                  <div className={`relative ${icon && 'pl-10'}`}>
                    <dt
                      className={`mb-3 text-h-s font-bold text-ink-100 ${
                        xIcon && 'pr-10'
                      }`}
                    >
                      {icon && (
                        <div
                          className={`${variantClasses} absolute left-0 top-0 flex items-center ml-0`}
                        >
                          {icon}
                        </div>
                      )}
                      {title}
                      {xIcon && (
                        <div
                          className={`${variantClasses} absolute right-0 top-0 flex items-center mr-0`}
                          onClick={handleCloseFunc}
                        >
                          <XMarkIcon className="h-5 w-5 hover:text-ink-80 cursor-pointer" />
                        </div>
                      )}
                    </dt>
                    <dd className="text-m font-regular text-ink-80">
                      {children}
                    </dd>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-end bg-ink-20 rounded-b-lg p-3">
                  <div
                    className={`w-full flex flex-col sm:flex-row space-x-0 gap-y-2 sm:space-x-3 sm:gap-y-0 ${
                      btmBtnBetween ? 'justify-between' : 'sm:w-auto'
                    }`}
                  >
                    {isBtmBtnLeft && (
                      <Button
                        otherClassName="flex order-last sm:order-first"
                        variant="outlined"
                        func={handleCancelBtn}
                      >
                        {btmLeftBtnTitle}
                      </Button>
                    )}
                    {isBtmBtnRight && (
                      <Button
                        variant={btmRightBtnVariant}
                        type="submit"
                        func={handleActionBtn}
                      >
                        {btmRightBtnTitle}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Modal;
