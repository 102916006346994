const cursor = {
  pointer: 'cursor-pointer',
  wait: 'cursor-wait',
  notAllowed: 'cursor-not-allowed'
};

const Checkbox = ({
  id,
  label,
  box = false,
  disabled = false,
  checked = false,
  func = () => {},
  otherClassName
}) => {
  let cursorClasses = cursor[disabled ? 'notAllowed' : 'pointer'];

  const handleChecked = () => {
    func(id, !checked);
  };

  return (
    <div className={`flex items-center ${otherClassName}`}>
      <input
        id={id}
        type="checkbox"
        value=""
        disabled={disabled}
        checked={checked}
        className={`
          w-4 h-4
          text-primary-main bg-ink-10 border-ink-50 rounded-md
          hover:border-primary-main
          focus:border-primary-main focus:outline focus:outline-[3px] focus:outline-offset-0 focus:ring-offset-0 focus:outline-primary-focus disabled:border-0 disabled:bg-ink-20 disabled:checked:bg-ink-50
          ${
            box &&
            'checked:bg-none checked:bg-clip-content checked:p-1 checked:rounded-md checked:border-primary-main disabled:border-ink-40 checked:shadow-[inset_0_0_0_0.25rem_#FFFFFF] disabled:checked:shadow-[inset_0_0_0_0.25rem_#F5F5F5]'
          }
          ${cursorClasses}
        `}
        onChange={handleChecked}
      />
      {label && (
        <label
          htmlFor={id}
          className={`ml-2 text-m font-regular text-ink-${
            disabled ? '60' : '90'
          } ${cursorClasses}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
