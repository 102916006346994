import axios from 'utils/axios';

export const getAllToken = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get('/settings/tiktok', {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const createNewToken = (res) => {
  return new Promise((resolve, reject) =>
    axios
      .post('/settings/tiktok', {
        ms_token: res
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getTokenById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/settings/tiktok/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const updateTokenById = (res) => {
  const {id, ms_token} = res;
  return new Promise((resolve, reject) =>
    axios
      .put(`/settings/tiktok/${id}`, {
        ms_token
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const deleteTokenById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .delete(`/settings/tiktok/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
