import {useState, useEffect} from 'react';
import {Spinner} from 'flowbite-react';
import {Button, CardTable} from 'components';
import {PlusIcon} from '@heroicons/react/20/solid';
import {useNavigate} from 'react-router-dom';
import * as service from 'services';
import {validationStatus, AlertValidation} from 'utils/validation';
import {paginationSizeOptions} from 'utils/paginationOptions';

const iconClasses = 'h-5 w-5';

const CaseManagement = () => {
  const navigate = useNavigate();
  const [dataColumns, setDataColumns] = useState([]);
  const [alert, setAlert] = useState();
  const [isFetch, setFetch] = useState(true);
  const [isFetchInside, setFetchInside] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    size: paginationSizeOptions[0],
    total: 1,
    count: 1
  });

  const [searchQuery, setSearchQuery] = useState('');

  const fetchTokenData = async (page, searchData, size, filterData) => {
    filterData && searchData === null && setSearchQuery('');

    const searchQueryData = searchData === '' ? null : searchQuery;
    const search = searchData || searchQueryData;
    const sizing = (size && size.value) || pagination.size.value;

    const paging = page || 1;
    const numbering = (paging - 1) * sizing;

    try {
      const data = await service.CaseManagement.getAllCase(
        paging,
        sizing,
        null,
        null,
        search,
        null
      );
      const newData = data.data.map((res, i) => ({
        no: numbering + i + 1,
        ...res
      }));
      setDataColumns(newData);
      setPagination((prev) => ({
        ...prev,
        current: data.page_number,
        total: data.total_pages,
        count: data.count
      }));
      setFetch(false);
      setFetchInside(false);
    } catch (err) {
      setAlert(AlertValidation(err));
    }
  };

  const deleteCaseManagementById = async (id, name) => {
    setFetch(true);
    try {
      await service.CaseManagement.deleteCaseById(id);
      await fetchTokenData();
      setAlert(
        AlertValidation(
          `Case Management '${name}' berhasil dihapus!`,
          'success'
        )
      );
    } catch (err) {
      setAlert(
        AlertValidation(
          `Case Management '${name}' gagal dihapus! Mohon ulangi lagi! (Error: ${validationStatus(
            err
          )})`
        )
      );
    } finally {
      setFetch(false);
    }
  };

  const updateCaseManagementById = async (id, name, description) => {
    setFetch(true);
    try {
      await service.CaseManagement.updateCaseById(id, name, description);
      await fetchTokenData();
      setAlert(
        AlertValidation(
          `Case Management berhasil diperbaharui menjadi '${name}'!`,
          'success'
        )
      );
    } catch (err) {
      setAlert(
        AlertValidation(
          `Case Management gagal diperbaharui! Mohon ulangi lagi! (Error: ${validationStatus(
            err
          )})`
        )
      );
    } finally {
      setFetch(false);
    }
  };

  const handlePagination = (page) => {
    setAlert(null);

    setPagination((prev) => ({
      ...prev,
      current: page
    }));

    fetchTokenData(page);
  };

  const handleShowEntries = (entries) => {
    const {value} = entries;
    setAlert(null);

    setPagination((prev) => ({
      ...prev,
      size: value
    }));

    fetchTokenData(null, null, value);
  };

  const handleSearch = (query) => {
    setFetchInside(true);
    fetchTokenData(null, query);
    setSearchQuery(query);
  };

  const handleClicked = (id) => {
    let topicId = id;
    if (topicId === '') topicId = 'default';
    navigate(`keywords/${topicId}`);
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const cardTableUser = (
    <CardTable
      data={dataColumns}
      page={pagination}
      searchable
      searchQuery={searchQuery}
      handlePagination={handlePagination}
      handleShowEntries={handleShowEntries}
      handleSearchQuery={handleSearch}
      handleClicked={handleClicked}
      handleEdit={updateCaseManagementById}
      isFetch={isFetchInside}
      handleDelete={deleteCaseManagementById}
      leftUpper={
        <div className="flex flex-row gap-3">
          <Button
            icon={<PlusIcon className={iconClasses} />}
            onClick={() => navigate('new-case-management')}
          >
            Buat Case Management
          </Button>
          <Button
            icon={<PlusIcon className={iconClasses} />}
            onClick={() => navigate('keywords/default/new-keyword')}
          >
            Buat Keyword
          </Button>
        </div>
      }
    />
  );

  return (
    <div className="space-y-6">
      {alert}
      <div className="text-h-l">Daftar Case Management</div>
      <div className="w-full bg-ink-10 p-6 rounded-lg shadow-2 space-y-3 text-center">
        {isFetch ? (
          <Spinner
            className="mr-3 fill-primary-main dark:text-ink-40"
            aria-label="Loading"
            size="lg"
          />
        ) : (
          cardTableUser
        )}
      </div>
    </div>
  );
};

export default CaseManagement;
