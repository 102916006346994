import axios from 'utils/axios';

export const getAllTopic = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null,
  search_query = null,
  sources = null,
  status = null,
  created_by = null,
  created_at_start = null,
  created_at_end = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get('/topics', {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order,
          search_query,
          sources,
          status,
          created_by,
          created_at_start,
          created_at_end
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const createNewTopic = (res, caseId) => {
  const {sources, name} = res;
  return new Promise((resolve, reject) =>
    axios
      .post('/topics', {
        sources,
        name,
        case_id: caseId
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getTopicById = (id, case_id = '') => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/topics/${id}`, {
        case_id
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const deleteTopicById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .delete(`/topics/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getWordCloudById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/topics/${id}/wordcloud`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getSocialNetworkById = (
  id,
  source = null,
  sentiment_analysis = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/topics/${id}/social-network`, {
        params: {
          source,
          sentiment_analysis
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getSocialNetworkV2ById = (
  id,
  source = null,
  sentiment_analysis = null,
  range_min = 10,
  range_max = 250,
  distance_factor = 5
) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/topics/${id}/social-network-v2`, {
        params: {
          source,
          sentiment_analysis,
          range_min,
          range_max,
          distance_factor
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const downloadSocialNetworkById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/topics/${id}/download`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const restartTopicById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/topics/${id}/restart`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
