import {
  HomeIcon,
  ChatBubbleBottomCenterTextIcon,
  FolderIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  KeyIcon,
  VideoCameraIcon
} from '@heroicons/react/20/solid';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

const Menu = ({name, icon, href, disabled, sidebar}) => {
  const initializeMenuClasses =
    'flex items-center p-2 rounded-lg text-m font-bold  focus:border-primary-main focus:outline focus:outline-[3px] focus:outline-primary-focus focus:outline-offset-0';

  return (
    <li>
      {disabled ? (
        <div
          className={`${initializeMenuClasses}bg-ink-20 text-ink-60 cursor-not-allowed select-none`}
        >
          {icon}
          <span className="flex-1 ml-3 whitespace-nowrap">{name}</span>
        </div>
      ) : (
        <NavLink
          exact="true"
          to={href}
          className={({isActive}) =>
            `${initializeMenuClasses}
              hover:bg-primary-surface hover:text-ink-90 hover:shadow-1
              ${
                isActive
                  ? 'bg-primary-main text-ink-10 shadow-1'
                  : 'text-ink-90'
              }
          `
          }
          disabled={disabled}
        >
          {icon}
          <span
            className={`${
              !sidebar && 'flex-1 hidden group-hover:inline-block'
            } ml-3 whitespace-nowrap`}
          >
            {name}
          </span>
        </NavLink>
      )}
    </li>
  );
};

const SideMenu = ({options, sidebar}) => {
  const initializeClasses =
    'space-y-2 font-medium pt-4 mt-4 border-t border-ink-40';

  return options.map((opt, i) => (
    <ul className={initializeClasses} key={i}>
      {opt.map((res) => {
        return (
          <Menu
            icon={res.icon}
            name={res.name}
            href={res.href}
            disabled={res.disabled}
            key={res.name}
            sidebar={sidebar}
          />
        );
      })}
    </ul>
  ));
};

const MenuSidebar = ({sidebar, is_admin, user}) => {
  const iconClasses = `${
    !sidebar && 'flex-1 group-hover:flex-none'
  } h-6 w-6 transition duration-75`;

  let menuOptions =
    is_admin && user !== 'pengajar'
      ? [
          [
            {
              name: 'Beranda',
              icon: <HomeIcon className={iconClasses} />,
              href: '/'
            },
            {
              name: 'Case Management',
              icon: <FolderIcon className={iconClasses} />,
              href: '/case-management'
            },
            {
              name: 'Video Modul',
              icon: <VideoCameraIcon className={iconClasses} />,
              href: '/video-module'
            },
            {
              name: 'Kelola Pengguna',
              icon: <UserGroupIcon className={iconClasses} />,
              href: '/user-management'
            },
            {
              name: 'Pengaturan',
              icon: <Cog6ToothIcon className={iconClasses} />,
              href: '/settings'
            },
            {
              name: 'Ganti Kata Sandi',
              icon: <KeyIcon className={iconClasses} />,
              href: '/change-password'
            }
          ]
        ]
      : [
          [
            {
              name: 'Beranda',
              icon: <HomeIcon className={iconClasses} />,
              href: '/'
            },
            {
              name: 'Case Management',
              icon: <FolderIcon className={iconClasses} />,
              href: '/case-management'
            },
            {
              name: 'Video Modul',
              icon: <VideoCameraIcon className={iconClasses} />,
              href: '/video-module'
            },
            {
              name: 'Ganti Kata Sandi',
              icon: <KeyIcon className={iconClasses} />,
              href: '/change-password'
            }
          ]
        ];

  return (
    <>
      <aside
        id="logo-sidebar"
        className={`
          fixed top-0 left-0 z-40 h-full shadow-1 transition-all group
          ${sidebar ? 'w-64' : 'w-20 -translate-x-full hover:w-64'}
          bg-ink-10 sm:translate-x-0
        `}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto">
          <div className="flex justify-center">
            <img
              src="./assets/img/logo.png"
              className="flex justify-center h-8"
              alt="Logo"
            />
          </div>
          <SideMenu options={menuOptions} sidebar={sidebar} />
        </div>
      </aside>
    </>
  );
};

const mapStateToProps = (state) => ({
  is_admin: state.auth.isAdmin,
  user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(MenuSidebar);
