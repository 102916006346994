import axios from 'utils/axios';

export const getAllCase = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null,
  search_query = null,
  created_by = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get('/case-management', {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order,
          search_query,
          created_by
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const createNewCase = (res) => {
  const {name, description} = res;

  return new Promise((resolve, reject) =>
    axios
      .post('/case-management', {
        name,
        description
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getCaseByDefault = (
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null,
  search_query = null,
  created_by = null,
  created_at_start = null,
  created_at_end = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/case-management/default`, {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order,
          search_query,
          created_by,
          created_at_start,
          created_at_end
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const getCaseById = (
  id,
  page_number = null,
  page_size = null,
  sort_key = null,
  sort_order = null,
  search_query = null,
  created_by = null,
  created_at_start = null,
  created_at_end = null
) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`/case-management/${id}`, {
        params: {
          page_number,
          page_size,
          sort_key,
          sort_order,
          search_query,
          created_by,
          created_at_start,
          created_at_end
        }
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const updateCaseById = (id, name, description) => {
  return new Promise((resolve, reject) =>
    axios
      .put(`/case-management/${id}`, {
        name,
        description
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};

export const deleteCaseById = (id) => {
  return new Promise((resolve, reject) =>
    axios
      .delete(`/case-management/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );
};
