import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';
import {InformationCircleIcon} from '@heroicons/react/24/outline';
import {useState} from 'react';

const variants = {
  danger: 'bg-danger-surface text-danger-main',
  success: 'bg-success-surface text-success-main',
  info: 'bg-info-surface text-info-main',
  warning: 'bg-warning-surface text-warning-pressed'
};

const icons = {
  danger: <ExclamationCircleIcon className="flex-shrink-0 h-5 w-5" />,
  success: <CheckCircleIcon className="flex-shrink-0 h-5 w-5" />,
  info: <InformationCircleIcon className="flex-shrink-0 h-5 w-5" />,
  warning: <ExclamationTriangleIcon className="flex-shrink-0 h-5 w-5" />
};

const Alert = ({
  variant = 'success',
  children = 'A simple info alert with an example link Give it a click if you like.'
}) => {
  let variantClasses = variants[variant];
  let variantIcons = icons[variant];

  const [alertActive, setAlertActive] = useState(true);

  const container = alertActive ? (
    <div
      id="alert"
      className={`flex p-4 mb-4 rounded-lg font-bold ${variantClasses} transition`}
      role="alert"
    >
      {variantIcons}
      <div className="ml-3 text-m">{children}</div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 p-1.5 inline-flex h-8 w-8"
        data-dismiss-target="#alert"
        aria-label="Close"
        onClick={() => setAlertActive(false)}
      >
        <span className="sr-only">Close</span>
        <XMarkIcon className="ml-auto h-5 w-5" />
      </button>
    </div>
  ) : null;

  return container;
};

export default Alert;
